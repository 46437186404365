<template>
  <BaseModal
    :show="showFilesModal"
    :modal-title="$t('documents.all_files')"
    modal-dialog-classes="credit-proposal-files-modal"
    size="md"
    @confirm="confirmFilesModal"
    @close="closeFilesModal"
  >
    <div
      v-for="(file, key) in computedFiles"
      :key="key"
      class="d-flex align-items-center"
    >
      <BaseCheckbox v-model="file.selected" />
      <div class="d-flex file">
        <span class="ps-1 ms-2 me-2">({{ CategoriesConstants[file.category] }})</span>
        <span
          class="text-truncate cursor-pointer"
          @click="openFileUrlInNewWindow(file.url)"
        >
          {{ getFileNameWithoutExtension(file.file_name) }}
        </span>
        <span
          class="cursor-pointer"
          @click="openFileUrlInNewWindow(file.url)"
          >.{{ getExtension(file.file_name) }}</span
        >
      </div>
    </div>
    <div v-if="!computedFiles?.length">
      {{ t('modal.no_files_found') }}
    </div>
  </BaseModal>
  <BaseModal
    v-if="showConfirmCreditProposalDataModal && creditProposalUpdateDataNotAllowed"
    :show="showConfirmCreditProposalDataModal"
    :modal-title="$t('ui.not_allowed')"
    size="md"
    custom-footer
    @confirm="closeCreditProposalModal"
    @close="closeCreditProposalModal"
  >
    {{ $t('modal.not_allowed_to_update_credit_proposal_data_while_ongoing_evaluation') }}
  </BaseModal>
  <SearchInput
    v-model="record.data.person_id"
    field-name="id"
    :entities="computedContactPersons"
    :initial-placeholder="$t('ui.placeholders.search_contact_person')"
    :label-content="$t('navigation.contactperson')"
    :errors="errorMessages['person_id']"
    wrapper-class="mb-3"
  />
  <LabelSelect
    v-model="record.data.limit_costs"
    :errors="errorMessages['limit_costs']"
    :label-content="$t('sales.credit_proposal.costs_limit')"
    :options="costsLimitOptions"
    :extra-columns="extraColumns"
    :align-label-center="false"
    wrapper-class="mb-3"
  >
    <template #extra>
      <BaseTextarea
        v-if="record.data.limit_costs === 'DISCOUNT'"
        v-model="record.data.limit_costs_description"
        :errors="errorMessages['limit_costs_description']"
        :placeholder="$t('sales.credit_proposal.explanation')"
        rows="8"
      />
    </template>
  </LabelSelect>

  <FormControlWrapper
    :label-content="$t('sales.credit_proposal.deviating_provisions')"
    :align-label-center="false"
    input-columns="col-12 col-lg-7 col-xl-8 mb-3"
  >
    <template v-if="record.data.deviating_provisions?.length">
      <div
        v-for="(deviatingProvision, key) in record.data.deviating_provisions"
        :key="key"
        class="row mb-3"
      >
        <div class="col-5 col-lg-4 col-xl-3">
          <BaseSelect
            v-model="deviatingProvision.deviating_key"
            :errors="errorMessages[`deviating_provisions.${key}.deviating_key`]"
            :options="deviatingProvisionsOptions"
          />
        </div>
        <div
          v-if="deviatingProvision.deviating_key === 'maximum_concentration_percentage'"
          class="col-5 col-lg-4 col-xl-3"
        >
          <MultiselectSearchInput
            v-model="deviatingProvision.deviating_debtors"
            :entities="getKeyValueDebtorsAnalyseRules"
            :array-of-key-value-objects="true"
            :initial-placeholder="$t('ui.placeholders.search_debtors')"
            :errors="errorMessages[`deviating_provisions.${key}.deviating_debtors`]"
            wrapper-class="mb-1"
          />
          <PercentageInput
            v-model="deviatingProvision.deviating_percentage"
            :errors="errorMessages[`deviating_provisions.${key}.deviating_percentage`]"
            @enter="handleSave"
          />
        </div>
        <div class="col-1">
          <FormIconButton
            icon="fa-regular fa-trash-can"
            button-size="sm"
            @click.prevent="removeDeviatingProvision(key)"
          />
        </div>
      </div>
    </template>
    <FormIconButton
      icon="fa-solid fa-plus"
      button-size="sm"
      @click.prevent="addDeviatingProvision"
    />
  </FormControlWrapper>
  <LabelSelect
    v-model="record.data.seasonal_influences"
    :errors="errorMessages['seasonal_influences']"
    :label-content="$t('sales.credit_proposal.are_there_seasonal_influences')"
    :options="yesNoOptions"
    :extra-columns="extraColumns"
    :align-label-center="false"
    wrapper-class="mb-3"
  >
    <template #extra>
      <BaseTextarea
        v-if="record.data.seasonal_influences === 'YES'"
        v-model="record.data.seasonal_influences_description"
        :errors="errorMessages['seasonal_influences_description']"
        :placeholder="$t('sales.credit_proposal.explanation')"
        rows="8"
      />
    </template>
  </LabelSelect>
  <LabelSelect
    v-model="record.data.billing_interval"
    :errors="errorMessages['billing_interval']"
    :label-content="$t('sales.credit_proposal.what_is_the_billing_interval')"
    :options="billingIntervalOptions"
    wrapper-class="mb-3"
  />
  <LabelSelect
    v-model="record.data.debtors_appear_as_creditors"
    :errors="errorMessages['debtors_appear_as_creditors']"
    :label-content="$t('sales.credit_proposal.are_there_debtors_who_also_appear_as_creditors')"
    :options="yesNoOptions"
    :align-label-center="false"
    :extra-columns="extraColumns"
    wrapper-class="mb-3"
  >
    <template #extra>
      <BaseTextarea
        v-if="record.data.debtors_appear_as_creditors === 'YES'"
        v-model="record.data.debtors_appear_as_creditors_description"
        :errors="errorMessages['debtors_appear_as_creditors_description']"
        :placeholder="$t('sales.credit_proposal.which_one_and_how_big_is_the_balance')"
        rows="8"
      />
    </template>
  </LabelSelect>
  <LabelSelect
    v-model="record.data.nature_of_billing"
    :errors="errorMessages['nature_of_billing']"
    :label-content="$t('sales.credit_proposal.nature_of_billing')"
    :options="natureOfBillingOptions"
    wrapper-class="mb-3"
  />
  <LabelSelect
    v-model="record.data.bonus_or_payment_discounts"
    :errors="errorMessages['bonus_or_payment_discounts']"
    :label-content="$t('sales.credit_proposal.are_bonuses_and_or_payment_discounts_given')"
    :options="yesNoOptions"
    :align-label-center="false"
    :extra-columns="extraColumns"
    wrapper-class="mb-3"
  >
    <template #extra>
      <BaseTextarea
        v-if="record.data.bonus_or_payment_discounts === 'YES'"
        v-model="record.data.bonus_or_payment_discounts_description"
        :errors="errorMessages['bonus_or_payment_discounts_description']"
        :placeholder="$t('sales.credit_proposal.explanation')"
        rows="8"
      />
    </template>
  </LabelSelect>
  <LabelSelect
    v-model="record.data.credit_insured_debtors"
    :errors="errorMessages['credit_insured_debtors']"
    :label-content="$t('sales.credit_proposal.are_the_debtors_credit_insured')"
    :options="yesNoOptions"
    :extra-columns="extraColumns"
    wrapper-class="mb-3"
  >
    <template #extra>
      <BaseSelect
        v-if="record.data.credit_insured_debtors === 'YES'"
        v-model="record.data.credit_insured_debtors_description"
        :errors="errorMessages['credit_insured_debtors_description']"
        :options="insurerOptions"
      />
    </template>
  </LabelSelect>
  <LabelTextarea
    v-model="record.data.write_offs_debtors_past_three_years"
    :errors="errorMessages['write_offs_debtors_past_three_years']"
    :label-content="$t('sales.credit_proposal.what_were_the_write_offs_on_debtors_in_the_past_three_financial_years')"
    :label-columns="labelColumnsTextarea"
    :input-columns="inputColumnsTextarea"
    rows="6"
    wrapper-class="mb-3"
  />
  <LabelSelect
    v-model="record.data.doubtful_debtors"
    :errors="errorMessages['doubtful_debtors']"
    :label-content="$t('sales.credit_proposal.are_there_any_bad_debts_at_the_moment')"
    :options="yesNoOptions"
    :align-label-center="false"
    :extra-columns="extraColumns"
    wrapper-class="mb-3"
  >
    <template #extra>
      <BaseTextarea
        v-if="record.data.doubtful_debtors === 'YES'"
        v-model="record.data.doubtful_debtors_description"
        :errors="errorMessages['doubtful_debtors_description']"
        :placeholder="$t('sales.credit_proposal.explanation')"
        rows="8"
      />
    </template>
  </LabelSelect>
  <LabelTextarea
    v-model="record.data.general_delivery_and_payment_conditions"
    :errors="errorMessages['general_delivery_and_payment_conditions']"
    :label-content="$t('sales.credit_proposal.which_general_terms_and_conditions_of_delivery_and_payment_are_used')"
    :label-columns="labelColumnsTextarea"
    :input-columns="inputColumnsTextarea"
    rows="6"
    class="mb-3"
  />
  <LabelSelect
    v-model="record.data.portfolio_concentrations"
    :errors="errorMessages['portfolio_concentrations']"
    :label-content="$t('sales.credit_proposal.are_there_concentrations_in_the_portfolio')"
    :options="yesNoOptions"
    :align-label-center="false"
    :extra-columns="extraColumns"
    wrapper-class="mb-3"
  >
    <template #extra>
      <BaseTextarea
        v-if="record.data.portfolio_concentrations === 'YES'"
        v-model="record.data.portfolio_concentrations_description"
        :errors="errorMessages['portfolio_concentrations_description']"
        :placeholder="$t('sales.credit_proposal.explanation')"
        rows="8"
      />
    </template>
  </LabelSelect>
  <LabelTextarea
    v-model="record.data.order_to_cash_process"
    :errors="errorMessages['order_to_cash_process']"
    :label-content="$t('sales.credit_proposal.describe_the_order_to_cash_process')"
    :tooltip-content="$t('sales.credit_proposal.describe_the_order_to_cash_process_info')"
    :placeholder="$t('sales.credit_proposal.describe_the_order_to_cash_process_info')"
    :label-columns="labelColumnsTextarea"
    :input-columns="inputColumnsTextarea"
    rows="6"
    class="mb-3"
  />
  <LabelTextarea
    v-model="record.data.general_description"
    :errors="errorMessages['general_description']"
    :label-content="$t('sales.credit_proposal.general')"
    :tooltip-content="$t('sales.credit_proposal.general_info')"
    :placeholder="$t('sales.credit_proposal.general_info')"
    :label-columns="labelColumnsTextarea"
    :input-columns="inputColumnsTextarea"
    rows="6"
    class="mb-3"
  />
  <LabelTextarea
    v-model="record.data.funding_request_reason"
    :errors="errorMessages['funding_request_reason']"
    :label-content="$t('sales.credit_proposal.reason_for_funding_request')"
    :tooltip-content="$t('sales.credit_proposal.reason_for_funding_request_info')"
    :placeholder="$t('sales.credit_proposal.reason_for_funding_request_info')"
    :label-columns="labelColumnsTextarea"
    :input-columns="inputColumnsTextarea"
    rows="6"
    class="mb-3"
  />
  <LabelTextarea
    v-model="record.data.current_funder_description"
    :errors="errorMessages['current_funder_description']"
    :label-content="$t('customer_data.current_financial_facility')"
    :tooltip-content="$t('sales.credit_proposal.current_financial_facility_info')"
    :placeholder="$t('sales.credit_proposal.current_financial_facility_info')"
    :label-columns="labelColumnsTextarea"
    :input-columns="inputColumnsTextarea"
    rows="6"
    class="mb-3"
  />
  <LabelTextarea
    v-model="record.data.requested_funding_description"
    :errors="errorMessages['requested_funding_description']"
    :label-content="$t('sales.credit_proposal.requested_funding')"
    :tooltip-content="$t('sales.credit_proposal.requested_funding_info')"
    :placeholder="$t('sales.credit_proposal.requested_funding_info')"
    :label-columns="labelColumnsTextarea"
    :input-columns="inputColumnsTextarea"
    rows="6"
    class="mb-3"
  />
  <LabelTextarea
    v-model="record.data.financials_description"
    :errors="errorMessages['financials_description']"
    :label-content="$t('sales.credit_proposal.financials')"
    :tooltip-content="$t('sales.credit_proposal.financials_info')"
    :placeholder="$t('sales.credit_proposal.financials_info')"
    :label-columns="labelColumnsTextarea"
    :input-columns="inputColumnsTextarea"
    rows="6"
    class="mb-3"
  />
  <FormControlWrapper
    :label-content="$t('sales.credit_proposal.attachments')"
    input-columns="col-12 col-lg-7 col-xl-8"
    :align-label-center="false"
  >
    <div
      class="icon icon-download icon-open-modal cursor-pointer mb-1"
      @click="openFilesModal"
    >
      <i class="fa-solid fa-arrow-up-right-from-square" />
    </div>
    <div
      v-for="(id, key) in record.data.file_ids"
      :key="key"
    >
      <FileTag
        v-if="getSelectedFile(id)"
        :file-key="key"
        :file-name="getSelectedFile(id)?.file_name"
        allow-remove
        @click="openFileUrlInNewWindow(getSelectedFile(id).url)"
        @remove="removeFile(id)"
      />
    </div>
  </FormControlWrapper>
  <FormFooter
    :pending="loading"
    :disabled="_.isEqual(record, originalRecord) || loading"
    @save="handleSave"
    @cancel="handleCancel"
  />
</template>

<script setup>
import FormIconButton from '@/components/buttons/FormIconButton.vue';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import MultiselectSearchInput from '@/components/form-controls/Multiselect/MultiselectSearchInput.vue';
import PercentageInput from '@/components/form-controls/PercentageInput.vue';
import BaseSelect from '@/components/form/BaseSelect.vue';
import BaseTextarea from '@/components/form/BaseTextarea.vue';
import FormControlWrapper from '@/components/form/FormControlWrapper.vue';
import LabelSelect from '@/components/form/LabelSelect.vue';
import LabelTextarea from '@/components/form/LabelTextarea.vue';
import CategoriesConstants from '@/configs/constants/Categories';
import BaseModal from '@/components/BaseModal.vue';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import apiClient from '@/services/ApiClient';
import { useEnumerationsStore } from '@/stores/enumerations';
import { ATRADIUS, COFACE, EULER_HERMES } from '@/configs/constants/Insurers';
import { useBeforeRouteLeave, useSetOriginalRecord, useSetRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import { useAuthorizationStore } from '@/stores/authorization';
import SearchInput from '@/components/form-controls/SearchInput/SearchInput.vue';
import { APPROVED, EVALUATION } from '@/configs/constants/Status';
import FileTag from '@/components/form-controls/FileTag.vue';
import BaseCheckbox from '@/components/form/BaseCheckbox.vue';
import {
  useCompareSalesStatusObject,
  useSetAndMergeSalesStatusObject,
} from '@/composables/records/UseAssessmentCommittee';
import {
  useGetProspectCreditProposalOwner,
  useGetProspectCreditProposalStatus,
  useMapContactPersons,
} from '@/composables/UseProspectViewData';
import { ref, computed, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { nanoid } from 'nanoid';
import { useRouter } from 'vue-router';
import _ from 'lodash';
import axios from 'axios';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  contactPersons: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['save', 'updatePreview']);

const router = useRouter();
const { t } = useI18n();
const authorizationStore = useAuthorizationStore();
const enumerationsStore = useEnumerationsStore();

const record = ref(props.initialRecord);
const originalRecord = ref({});
const validationRules = ref({});
const errorMessages = ref([]);
const loading = ref(false);
const formId = nanoid();
const users = ref(structuredClone(enumerationsStore.data.users));
const showFilesModal = ref(false);
const labelColumnsTextarea = 'col-12 col-lg-4 col-xl-2';
const inputColumnsTextarea = 'col-12 col-lg-5 col-xl-4';
const extraColumns = 'col-12 col-lg-3 col-xl-4';
const showConfirmCreditProposalDataModal = ref(false);
const creditProposalUpdateDataNotAllowed =
  useGetProspectCreditProposalStatus() === EVALUATION && !useGetProspectCreditProposalOwner();
let originalCreditProposalObject = {};

const costsLimitOptions = {
  STANDARD: t('sales.credit_proposal.costs_limit_options.standard'),
  DISCOUNT: t('sales.credit_proposal.costs_limit_options.discount'),
};
const deviatingProvisionsOptions = {
  maximum_concentration_percentage: t(
    'sales.credit_proposal.deviating_provisions_options.maximum_concentration_percentage'
  ),
};
const yesNoOptions = {
  YES: t('ui.yes'),
  NO: t('ui.no'),
  UNKNOWN: t('ui.unknown'),
};
const billingIntervalOptions = {
  DAILY: t('ui.time.daily'),
  WEEKLY: t('ui.time.weekly'),
  MONTHLY: t('ui.time.monthly'),
};
const natureOfBillingOptions = {
  INSTALLMENT_BILLING: t('sales.credit_proposal.nature_of_billing_options.installment_billing'),
  SUBSCRIPTIONS: t('sales.credit_proposal.nature_of_billing_options.subscriptions'),
  ADVANCE_BILLING: t('sales.credit_proposal.nature_of_billing_options.advance_billing'),
  BACK_BILLING: t('sales.credit_proposal.nature_of_billing_options.back_billing'),
};
const insurerOptions = {
  atradius: ATRADIUS,
  coface: COFACE,
  euler_hermes: EULER_HERMES,
};

const computedContactPersons = computed(() => {
  unsetPersonIdIfSelectedContactPersonDoesNotExists();
  return useMapContactPersons(props.contactPersons);
});

const computedFiles = computed(() => {
  setSelectedFileIds();
  return record.value.sales_status?.customer_documents?.files;
});

const getKeyValueDebtorsAnalyseRules = computed(() => {
  const debtorAnalyseRules = {};
  if (record.value.sales_status.debtor_analyse.debtor_analyse_rules) {
    record.value.sales_status.debtor_analyse.debtor_analyse_rules.map((debtorAnalyseRule) => {
      debtorAnalyseRules[debtorAnalyseRule.id] = debtorAnalyseRule.debtor_name;
    });
  }
  return debtorAnalyseRules;
});

onBeforeMount(async () => {
  useBeforeRouteLeave();

  setSelectedFileIds();
  record.value.data['file_ids'] = getSelectedFileIds();

  record.value = useSetRecord(record.value, formId);
  originalRecord.value = useSetOriginalRecord(record.value, formId);

  if (creditProposalUpdateDataNotAllowed) {
    originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
  }

  validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value.data);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value.data);
  delete users.value[authorizationStore.user.id];
});

async function handleSave() {
  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  if (creditProposalUpdateDataNotAllowed) {
    const latestCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));

    const creditProposalHasDataDifference = useCompareSalesStatusObject(
      originalCreditProposalObject,
      latestCreditProposalObject
    );

    if (creditProposalHasDataDifference) {
      showConfirmCreditProposalDataModal.value = true;
      return;
    }
  }

  validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value.data);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value.data);

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  await save();
}

async function save() {
  try {
    loading.value = true;
    checkAndResetExtraFields();

    let response;
    let recordCopy = structuredClone(record.value.data);
    recordCopy['file_ids'] = JSON.stringify(getSelectedFileIds());
    recordCopy.deviating_provisions = JSON.stringify(record.value.data.deviating_provisions);

    if (!record.value.data.id) {
      response = await apiClient.request(
        'post',
        `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal`,
        null,
        recordCopy
      );
      useSetToast('success', t('toast.success.credit_proposal_details_successfully_created'));
      record.value.data.id = response.data.id;
    } else {
      response = await apiClient.request(
        'put',
        `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/credit_proposal/${record.value.data.id}`,
        null,
        recordCopy
      );
      useSetToast('success', t('toast.success.credit_proposal_details_successfully_updated'));
    }
    emit('save', {
      customerLogs: response.sales_status.customer_log,
      creditProposalPreview: response.credit_proposal_preview,
    });

    record.value.data = response.data;
    originalRecord.value = useSetOriginalRecord(record.value, formId);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    if (!record.value.data.id) {
      useSetToast(
        'error',
        t('toast.error.creating_credit_proposal_details') + ':' + '<br>' + error?.response?.data?.message
      );
      console.error('Error while creating credit proposal: ', error);
    } else {
      useSetToast(
        'error',
        t('toast.error.updating_credit_proposal_details') + ':' + '<br>' + error?.response?.data?.message
      );
      console.error('Error while updating credit proposal: ', error);
    }
  } finally {
    loading.value = false;
  }
}

function handleCancel() {
  record.value = useSetRecord(originalRecord.value, formId);
  if (creditProposalUpdateDataNotAllowed) {
    originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
  }
  errorMessages.value = [];
}

function checkAndResetExtraFields() {
  if (record.value.data.seasonal_influences !== 'YES') {
    record.value.data.seasonal_influences_description = null;
  }

  if (record.value.data.debtors_appear_as_creditors !== 'YES') {
    record.value.data.debtors_appear_as_creditors_description = null;
  }

  if (record.value.data.bonus_or_payment_discounts !== 'YES') {
    record.value.data.bonus_or_payment_discounts_description = null;
  }

  if (record.value.data.credit_insured_debtors !== 'YES') {
    record.value.data.credit_insured_debtors_description = null;
  }

  if (record.value.data.doubtful_debtors !== 'YES') {
    record.value.data.doubtful_debtors_description = null;
  }

  if (record.value.data.portfolio_concentrations !== 'YES') {
    record.value.data.portfolio_concentrations_description = null;
  }

  if (record.value.data.limit_costs !== 'DISCOUNT') {
    record.value.data.limit_costs_description = null;
  }

  record.value.data.deviating_provisions = record.value.data.deviating_provisions.map((provision) => {
    if (provision.deviating_key !== 'maximum_concentration_percentage') {
      provision.deviating_key = null;
      provision.deviating_percentage = null;
      provision.deviating_debtors = [];
    }
    return provision;
  });
}

function addDeviatingProvision() {
  record.value.data.deviating_provisions.push({
    deviating_key: null,
    deviating_debtors: [],
    deviating_percentage: null,
  });
}
function removeDeviatingProvision(index) {
  record.value.data.deviating_provisions.splice(index, 1);
}

function getSelectedFile(id) {
  return record.value.sales_status?.customer_documents?.files.find((file) => file.id === id);
}
function setSelectedFileIds() {
  record.value.sales_status?.customer_documents?.files.forEach((file) => {
    file.selected = record.value.data.file_ids?.includes(file.id) || false;
  });
}
function getSelectedFileIds() {
  return record.value.sales_status?.customer_documents?.files.filter((file) => file.selected).map((file) => file.id);
}
function removeFile(fileId) {
  record.value.data.file_ids = record.value.data.file_ids.filter((id) => {
    return id !== fileId;
  });
  setSelectedFileIds();
}

function openFilesModal() {
  showFilesModal.value = true;
}
function closeFilesModal() {
  setSelectedFileIds();
  showFilesModal.value = false;
}
function confirmFilesModal() {
  record.value.data.file_ids = getSelectedFileIds();
  showFilesModal.value = false;
}

function getFileNameWithoutExtension(fileName) {
  return fileName.split('.').shift();
}
function getExtension(fileName) {
  return fileName.split('.').pop();
}

function getCreditProposalObject() {
  const creditProposalData = structuredClone(record.value.sales_status);
  creditProposalData['credit_proposal'] = structuredClone(record.value.data);
  return { sales_status: creditProposalData };
}

function closeCreditProposalModal() {
  showConfirmCreditProposalDataModal.value = false;
}

function openFileUrlInNewWindow(url) {
  url = axios.defaults.baseURL + url;
  window.open(url, '_blank', 'noopener');
}

function unsetPersonIdIfSelectedContactPersonDoesNotExists() {
  const contactPersons = props.contactPersons;
  if (!Number.isInteger(record.value.data?.person_id)) {
    return;
  }

  const index = contactPersons.findIndex((contactPerson) => {
    return parseInt(contactPerson.person.id) === parseInt(record.value.data.person_id);
  });

  if (index === -1) {
    record.value.data.person_id = null;
    originalRecord.value.data.person_id = null;
  }
}
</script>
