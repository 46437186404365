export default {
  ui: {
    numbers: {
      total: 'Totaal',
      readable_counts: {
        1: 'Eerste',
        2: 'Tweede',
        3: 'Derde',
        4: 'Vierde',
        5: 'Vijfde',
      },
    },
    placeholders: {
      search: 'Zoeken..',
      search_contact_person: 'Contactpersoon zoeken..',
      search_debtor: 'Debiteur zoeken..',
      search_debtors: 'Debiteuren zoeken..',
      make_a_choice: 'Maak een keuze..',
      search_or_add: 'Zoeken of toevoegen..',
    },
    add: 'Toevoegen',
    empty: 'Leeg',
    edit: 'Aanpassen',
    confirm: 'Bevestigen',
    send: 'Versturen',
    save: 'Opslaan',
    cancel: 'Annuleren',
    remove: 'Verwijderen',
    download: 'Download',
    keep: 'Behouden',
    previous: 'Vorige',
    next: 'Volgende',
    notifications: 'Notificaties',
    close: 'Sluiten',
    not_allowed: 'Niet toegestaan',
    no_form: 'Geen formulier beschikbaar.',
    preview: 'Preview',
    time: {
      month: '{count} maand | {count} maanden',
      day: '{count} dag | {count} dagen',
      average_days: '{days} dagen',
      on: 'op',
      daily: 'Dagelijks',
      weekly: 'Wekelijks',
      monthly: 'Maandelijks',
    },
    download_pdf: 'Download PDF',
    send_email: 'Verstuur email',
    status: 'Status',
    button_search: 'Zoeken',
    yes: 'Ja',
    no: 'Nee',
    unknown: 'Niet bekend',
    alert_status: {
      info: 'Info',
      success: 'Succes',
      warning: 'Waarschuwing',
      error: 'Foutmelding',
    },
    summary: 'Overzicht',
  },
  navigation: {
    home: 'Home',
    clients: 'Klanten',
    client: 'Klant',
    company: 'Bedrijf',
    company_shareholders: 'Aandeelhouders',
    persons: 'Personen',
    contactperson: 'Contactpersoon',
    sales: 'Sales',
    customers: '@:ui.summary',
    customer: 'Klant',
    creditsafe: 'Creditsafe',
    master_data: 'Stamgegevens',
    annual_figures: 'Cijfers',
    debtor_creditor_analyses: 'Debiteur / Crediteur analyse',
    debtor_analyses: 'Debiteur analyse',
    creditor_analyses: 'Crediteur analyse',
    organogram: 'Organogram',
    documents: 'Documenten',
    offer: 'Offerte',
    details: 'Details',
    offer_certainties: 'Zekerheden',
    offer_document_format: 'Opmaak document',
    offer_required_documents: 'Bij te voegen bescheiden',
    credit_proposal: 'Kredietvoorstel',
    assessment_committee: '@:sales.credit_proposal.assessment_committee.evaluations commissie',
    notes: 'Notulen',
    contract: 'Contract',
  },
  modal: {
    director_is_a_person: 'Bestuurder is een persoon',
    person: 'Persoon',
    add_person: 'Persoon toevoegen',
    update_person: 'Persoon bijwerken',
    select_person: 'Persoon selecteren',
    select_company: 'Bedrijf selecteren',
    add_company: 'Bedrijf toevoegen',
    update_company: 'Bedrijf bijwerken',
    add_company_or_person: 'Bedrijf of persoon toevoegen',
    add_intermediary: 'Tussenpersoon toevoegen',
    delete_intermediary: 'Tussenpersoon verwijderen',
    create: 'Aanmaken',
    update: 'Bijwerken',
    creditsafe_data: 'Creditsafe gegevens',
    confirm_creditsafe_data: 'Creditsafe gegevens bevestigen',
    creditsafe_retrieve_company: 'Creditsafe bedrijf ophalen',
    send_offer_email: 'Offerte email versturen',
    create_note: 'Notitie aanmaken',
    update_note: 'Notitie bijwerken',
    no_files_found: 'Geen bestanden gevonden.',
    warning_text_unsaved_changes_when_leaving_page:
      'Weet je zeker dat je deze pagina wilt verlaten? Alle niet-opgeslagen wijzigingen gaan verloren.',
    warning_text_unsaved_changes_when_leaving_contact_person_form:
      'Weet je zeker dat je deze contactpersoon wilt verlaten? Alle niet-opgeslagen wijzigingen gaan verloren.',
    warning_text_when_deleting_document: 'Weet je zeker dat je het document {name} wilt verwijderen?',
    warning_text_when_deleting_intermediary: 'Weet je zeker dat je de tussenpersoon wilt verwijderen?',
    warning_text_when_deleting_documents: 'Weet je zeker dat je de volgende documenten wilt verwijderen?{documents}',
    warning_text_when_deleting_card: 'Weet je zeker dat je {currentCard} wilt verwijderen?',
    warning_text_when_deleting_cards:
      'Weet je zeker dat je {currentCard} wilt verwijderen? De volgende kaarten die hieronder vallen worden ook verwijderd{cards}',
    warning_text_when_deleting_contact_person: 'Weet je zeker dat je contactpersoon {name} wilt verwijderen?',
    are_you_sure_you_want_to_save_data: 'Weet je zeker dat je de gegevens wilt opslaan?',
    warning_text_when_updating_prospect_organogram_company:
      'Het organogram van de volgende prospects maken gebruik van dit bedrijf en zullen ook worden aangepast:',
    warning_text_when_deleting_contact_person_of_prospect_organogram_company:
      'Het organogram van de volgende prospects maken gebruik van een bedrijf waar deze persoon een bestuurder van is:',
    warning_text_when_updating_prospect_company:
      'De bedrijfsgegevens van prospect {companyName} met id {companyId} zullen ook worden aangepast.',
    warning_text_when_changing_status_old_to_new:
      'Weet je zeker dat je de status van {old} naar {new} wilt veranderen?',
    not_allowed_to_update_credit_proposal_data_while_ongoing_evaluation:
      'Gegevens van het kredietvoorstel aanpassen is niet toegestaan, er is een lopend kredietvoorstel.',
    warning_text_when_accepting_credit_proposal_evaluations:
      'Weet je zeker dat je het kredietvoorstel wilt accepteren? De beoordelingen kunnen na het accepteren niet opnieuw worden gezet. Een kredietvoorstel document wordt opgeslagen en een contract kan worden aangemaakt.\n\nAlle velden in het rood worden zwart gemarkeerd zodat eventuele aanpassingen voor het contract opnieuw ingezien kunnen worden.',
    warning_text_when_rejecting_credit_proposal_evaluations:
      'Weet je zeker dat je het kredietvoorstel wilt weigeren? De beoordelingen kunnen na het weigeren opnieuw worden gezet.',
  },
  tooltip: {
    creditsafe_select_all_countries:
      "Selecteer 'All landen' om door alle beschikbare creditsafe landen te zoeken. Zoekresultaten voor individuele landen zijn effectiever en sneller opgehaald",
    creditsafe_postal_code_wildcard:
      'Een * kan als wildcard gebruikt worden. Voorbeeld: 3011* representeert alles wat met 3011 begint.',
    contact_person_settings_value: 'Tekenbevoegdheid (gezamenlijk bevoegd) van een contactpersoon',
    click_for_more_information: 'Klik voor meer informatie',
  },
  validation: {
    value_is_required: 'Value is required',
    kvk_or_btw_or_combination_other_fields_required:
      'Een KVK of BTW nummer of een combinatie van de overige velden is vereist',
    kvk_or_btw_is_required: 'Een KVK of BTW nummer is vereist',
  },
  description: {
    description: 'Omschrijving',
    creditsafe_description:
      'Gebruik een KVK of VAT nummer als unieke identificatie. Als er geen unieke identificatie beschikbaar is, gebruik dan postcode en naam voor het beste slagingspercentage.',
    creditsafe_modal_description: 'Controleer bovenstaande gegevens voordat ze worden opgeslagen',
    adjusted_data_is_marked_in_red: 'Aangepaste data is rood gemarkeerd',
  },
  general: {
    name: 'Naam',
    phone: 'Telefoon',
    mobile: 'Mobiel',
    website: 'Website',
    email: 'E-mail',
    company_name: 'Bedrijfsnaam',
    company_registration_number: 'KVK nummer',
    vat_number: 'BTW nummer',
    registration_number: 'Registratienummer',
    registration_name: 'Registratienaam',
    id: 'ID',
    welcome_email_sent_at: 'Welkom e-mail verstuurd op:',
    company: 'Bedrijf',
    copy_sbi_code: 'SBI-code kopiëren',
    all_countries: 'Alle landen',
    last_adjustment: 'Laatste aanpassing',
    retrieve: 'Ophalen',
    and: 'En',
    message: 'Bericht',
    employee: 'Medewerker',
  },
  address: {
    registered_office: 'Statutair gevestigd',
    address_information: 'Adres',
    street: 'Straat',
    address_information_description: 'Straat + huisnummer, postcode, plaats',
    postal_code: 'Postcode',
    city: 'Plaats',
    country: 'Land',
    contact_address: 'Contactadres',
  },
  contact_persons: {
    job_title: 'Functie',
    competence: 'Tekenbevoegdheid',
    contact_type: 'Contacttype',
    salutation: 'Aanhef',
    initials: 'Initialen',
    first_name: 'Voornaam',
    last_name: 'Achternaam',
    all_persons: 'Alle personen',
    contact_persons: 'Contactpersonen',
  },
  intermediary: {
    intermediaries: 'Tussenpersonen',
    iban: 'IBAN',
    credit_insurance: 'Kredietverzekering',
    factoring_provision_percentage: 'Factoring provisie percentage',
    interest_provision_percentage: 'Rente provisie percentage',
    prospects: 'Prospects',
    note: 'Notitie',
    notes: 'Notities',
    prospect_company: 'Prospect bedrijf',
  },
  client: {
    company_details: 'Bedrijfsgegevens',
    legal_form: 'Bedrijfsvorm',
    company: 'Bedrijf',
    branch: 'Branch',
    accounting_software: 'Boekhoudprogramma',
  },
  company: {
    legal_form: 'Rechtsvorm',
  },
  sales: {
    log: {
      title: 'Logboek',
      date: 'Datum',
      action: 'Actie',
      who: 'Wie',
    },
    progress_and_actions: {
      email_out: 'E-mail uit',
      email_in: 'E-mail in',
      phone_out: 'Telefoon uit',
      phone_in: 'Telefoon in',
      visit_prospect: 'Bezoek bij prospect',
      memo: 'Memo',
      add_alert: 'Alert toevoegen',
      execute_alert_on: 'Alert uitvoeren op',
      assign_alert_to: 'Alert toewijzen aan',
      alert: 'Alert',
      execute_on: 'Uitvoeren op',
      execute_by: 'Uitvoeren door',
      message: 'Notitie',
      alert_message: 'Alert notitie',
      event: 'Gebeurtenis',
    },
    status: {
      lead: 'Lead',
      prospect: 'Prospect',
      offer: '@:navigation.offer',
      credit_proposal: '@:navigation.credit_proposal',
      contract: '@:navigation.contract',
      client: '@:navigation.client',
      rejected: 'Afgewezen',
      passed: 'Afgelegd',
    },
    list: {
      created: 'Aangemaakt',
      progress: 'Voortgang',
      account_manager: 'Verantwoordelijke',
      added_by: 'Toegevoegd door',
      credit_requirement_level: 'Hoogte kredietbehoefte',
      updated: 'Aangepast',
    },
    debtor_analyses: {
      debtor: 'Debiteur | Debiteuren',
      debtors: 'Debiteuren',
      debtor_name: '@:sales.debtor_analyses.debtor naam',
      total_amount: 'Totaalbedrag',
      accounts_receivable_balance: 'Debiteurensaldo',
      grade: 'Grade',
      grade_score: 'Grade score',
      creditsafe_score: 'CS score',
      creditsafe_score_shorthand: 'CS',
      insurer: 'Verzekeraar',
      limit: 'Limiet',
      particularities: 'Bijzonderheden',
      unfinanceable: 'Niet @.lower:sales.debtor_analyses.financeable',
      financeable: 'Financierbaar',
      percentage_total_balance: '% totale saldo',
      retrieve_debtor: 'Debiteur ophalen',
      debtor_data: 'Debtorgegevens',
    },
    creditor_analyses: {
      creditor: 'Crediteur | Crediteuren',
      creditors_balance: 'Crediteurensaldo',
      creditor_name: 'Crediteuren naam',
      total_amount: '@:sales.debtor_analyses.total_amount',
      nature_of_product_or_service: 'Aard product/dienst',
      particularities: '@:sales.debtor_analyses.particularities',
    },
    credit_proposal: {
      costs_limit: 'Limietkosten',
      credit_limit: 'Kredietlimiet ({currency})',
      costs_limit_options: {
        standard: 'Standaard',
        discount: 'Korting',
      },
      deviating_provisions: 'Afwijkende bepalingen',
      deviating_provisions_options: {
        maximum_concentration_percentage: 'Maximaal concentratie percentage',
      },
      are_there_seasonal_influences: 'Is er sprake van seizoensinvloeden?',
      explanation: 'Uitleg',
      what_is_the_billing_interval: 'Hoe vaak wordt er gefactureerd?',
      are_there_debtors_who_also_appear_as_creditors: 'Zijn er debiteuren die ook als crediteur voorkomen?',
      which_one_and_how_big_is_the_balance: 'Welke? Hoe groot is het saldo?',
      nature_of_billing: 'Aard van de facturatie',
      nature_of_billing_options: {
        installment_billing: 'Termijnfacturatie',
        subscriptions: 'Abonnementen',
        advance_billing: 'Vooruit facturatie',
        back_billing: 'Achteraf facturatie',
      },
      are_bonuses_and_or_payment_discounts_given: 'Worden er bonussen en/of betalingskortingen gegeven?',
      are_the_debtors_credit_insured: 'Zijn de debiteuren kredietverzekerd?',
      what_were_the_write_offs_on_debtors_in_the_past_three_financial_years:
        'Wat waren de afboekingen op debiteuren, de afgelopen 3 boekjaren?',
      are_there_any_bad_debts_at_the_moment: 'Zijn er op dit ogenblik dubieuze debiteuren?',
      which_general_terms_and_conditions_of_delivery_and_payment_are_used:
        'Welke algemene leverings- en betalingsvoorwaarden worden er gehanteerd?',
      are_there_concentrations_in_the_portfolio: 'Is er sprake van concentraties in de portefeuille?',
      describe_the_order_to_cash_process: 'Beschrijf het proces van “order-to-cash”',
      describe_the_order_to_cash_process_info:
        'Beschrijf hoe een verkooporder tot stand komt. Zijn dit getekende orders? Wordt er verwezen naar de ALBV? Wordt er bij aflevering nog een ontvangstbewijs getekend? Zo ja, welke? Hoe betalen de klanten, op rekening of contant? Waarom?',
      general: 'Algemeen',
      general_info:
        'Achtergrond ondernemer, onderneming, visie & strategie, activiteiten, certificering en hoe gereguleerd, brancheontwikkelingen, concurrentiepositie, doelgroep(analyse), ontwikkelingen onderneming etc.',
      reason_for_funding_request: 'Aanleiding financieringsverzoek',
      reason_for_funding_request_info:
        'Aanleiding toelichten, bijvoorbeeld omzetgroei, uitbreidingsinvestering, grote order, overname, pieken in omzet etc.',
      current_financial_facilities: 'Huidige financiers',
      current_financial_facility_info:
        'Hoe nu gefinancierd? Waarom voldoet dit niet meer of juist wel? Welke zekerheden zijn er verstrekt? Evt. condities huidige financiering etc.',
      requested_funding: 'Gevraagde financiering',
      requested_funding_info:
        'Reden van de kredietbehoefte geven. Toelichting op de hoogte van de gevraagde financiering geven, ter onderbouwing liquiditeitsbegroting/cashflowforecast toevoegen en toelichten etc.',
      financials: 'Financials',
      financials_info:
        'Naam Accountant/Boekhouder, Balans, winst- en verliesrekening en evt budget schematiseren of in bijlage toevoegen en kort de belangrijkste balansposten, trends in winst- en verliesrekening en budget beschrijven.',
      attachments: 'Bijlagen',
      attachment: 'Bijlage',
      request_evaluation: 'Beoordeling aanvragen',
      send_credit_proposal_to_commission: 'Kredietvoorstel naar commissie verzenden',
      remove_employee_from_evaluations: 'Medewerker uit de beoordelingen verwijderen',
      removed_by_the_applicant: 'Verwijderd door de aanvrager',
      accompanying_note: 'Bijgaande notitie',
      the_credit_proposal_has_been_sent: 'Het kredietvoorstel is verzonden!',
      assessment_committee: {
        applicant: 'Aanvrager',
        evaluations: 'Beoordeling | Beoordelingen',
        evaluation: 'Beoordeling',
        comment: 'Opmerking',
        current_facility: 'Huidige faciliteit ({currency})',
      },
      proposal: {
        data_applicant: 'Gegevens aanvrager',
        visiting_address: 'Bezoekadres',
        business_activity: 'Bedrijfsactiviteit',
        factoring_rates_and_provisions: 'Tarieven en bepalingen factoring',
        factoring_fee_inland: '@:offer.factoring_fee binnenland',
        interest_per_year: 'Rente per jaar',
        euribor_interest_term_plus_raise_advance_interest_rate:
          '{months}-maands Euribor + {percentage}% waarbij Euribor minimaal 0% is',
        credit_provision_percentage_per_month: '{percentage}% per maand',
        duration_contract: 'Looptijd contract',
        duration_contract_in_years_and_months_with_a_notice_period_of_months:
          '{duration_years} jaar ({duration_months} maanden) met een opzegtermijn van {notice_months} maanden',
        own_risk_credit_insurance_per_year_excl_tax: '{amount} {currency} per contractjaar (excl. BTW)',
        rates_and_provisions_for_transaction_financing: 'Tarieven en bepalingen @.lower:offer.transaction_financing',
        percentage_based_on_daily_interest_payment: '{percentage}% op basis van dagrente vergoeding',
        maximum_advance_on_purchase_value: '@:offer.maximum_advance op inkoopwaarde',
        maximum_term_per_transaction: 'Maximale looptijd per transactie',
        data: 'Gegevens',
        annual_sales_excl_tax: 'Jaarlijkse omzet (excl. BTW)',
        average_invoice_value: 'Gemiddelde factuurwaarde',
        average_payment_term: 'Gemiddelde betalingstermijn',
        certainties: '@:navigation.offer_certainties',
        factorability: 'Factorabiliteit',
        total_balance: 'Totaal saldo ({currency})',
        top_five_debtors: 'Top 5 debiteuren',
        top_five_creditors: 'Top 5 crediteuren',
        financier: 'Financier',
      },
      notes: {
        background_request: 'Achtergrond aanvraag',
        what_is_the_background_of_the_request: 'Wat is de achtergrond van de aanvraag?',
        main_risks: "Belangrijkste risico's",
        what_are_the_main_risks: "Wat zijn de belangrijkste risico's?",
        pricing: 'Pricing',
        decision: 'Besluit',
        what_is_the_decision: 'Wat is het besluit?',
        actions_still_to_be_taken: 'Nog te nemen acties',
        what_actions_still_need_to_be_taken: 'Welke acties moeten er nog worden genomen?',
      },
      reset_evaluations: 'Beoordelingen opnieuw zetten',
      submit_evaluations: 'Beoordelingen definitief maken',
    },
    annual_figures: {
      title: 'Jaarcijfers',
      in_currency_times_thousand: 'In {currency} 1.000',
      revenue: 'Omzet',
      gross_profit: 'Brutoresultaat',
      gross_margin_percentage: 'Brutomarge %',
      opex: 'OPEX',
      ebitda: 'EBITDA',
      net_profit: 'Netto winst',
    },
    organogram: {
      change_layout: 'Indeling veranderen',
      top: 'Boven',
      bottom: 'Onder',
      left: 'Links',
      right: 'Rechts',
      full_screen: 'Volledig scherm',
      center: 'Centreren',
      expand_all: 'Alles uitklappen',
      collapse_all: 'Alles inklappen',
      company_organogram: 'Organogram (bedrijf)',
      shareholder_organogram: 'Organogram (aandeelhouders)',
    },
  },
  customer_data: {
    currency: 'Munteenheid',
    correspondence_language: 'Taal van correspondentie',
    lead_channel: 'Binnengekomen via',
    intermediary: 'Tussenpersoon',
    intermediary_company: 'Tussenpersoon bedrijf',
    credit_need_amount: 'Hoogte kredietbehoefte',
    annual_revenue_amount: 'Jaaromzet',
    forecast_annual_revenue_amount: 'Prognose jaaromzet',
    debtor_balance_amount: 'Debiteurensaldo',
    debtors_count: 'Aantal actieve debiteuren',
    invoices_count: 'Aantal facturen per jaar',
    average_invoice_amount: 'Gem. factuurbedrag',
    default_payment_term_days: 'Standaard betalingstermijn',
    average_payment_term_days: 'Gem. betalingstermijn',
    current_financial_facility: 'Huidige financier',
    current_facility: 'Huidige faciliteit',
    facility_type: 'Type faciliteit',
    manually_create_customer: 'Klant handmatig aanmaken',
    retrieve_company: 'Bedrijf ophalen',
  },
  offer: {
    other_document_signer: 'Andere ondertekenaar',
    document_signer: 'Ondertekenaar',
    addressee: 'Geadresseerde',
    informal_salutation: 'Informele aanhef',
    maximum_purchase_price: 'Maximale koopsom',
    maximum_advance: 'Maximale bevoorschotting',
    factoring_fee: 'Factoring fee',
    add_factoring_fee_abroad: 'Factoring fee buitenland toevoegen',
    factoring_fee_abroad: 'Factoring fee buitenland',
    current_interest_rate: 'Huidige rente',
    euribor_interest_term: 'Euribor rente termijn',
    raise_advance_interest_rate: 'Renteopslag',
    credit_provision: 'Kredietprovisie',
    credit_insurance: 'Kredietverzekering',
    setup_fee: 'Setup fee',
    minimum_factoring_fee_per_contract_year: 'Minimale factoring fee per contractjaar',
    duration: 'Looptijd',
    notice_period: 'Opzegtermijn',
    account_courant_credit: 'Rek. courant krediet',
    scoring_change: 'Scoringskans',
    own_risk_credit_insurance: 'Eigen risico kredietverzekering',
    transaction_financing: 'Transactiefinanciering',
    maximum_percentage_annual_revenue: 'Maximaal % van de jaaromzet',
    maximum_purchase_orders_revenue: 'Maximaal van de inkooporders',
    maximum_financing_limit: 'Maximale financieringslimiet',
    monthly_interest_rate: 'Rente per maand',
    based_on_the_daily_interest_calculation: 'op basis van de dagrenteberekening',
    maximum_monthly_duration: 'Maximale looptijd',
    in_months_of_every_single_transaction: 'in maanden, van iedere afzonderlijke transactie',
    personalized_introduction: 'Gepersonaliseerde inleiding',
    preview: {
      save_as_document: 'Als document opslaan',
      send_to: 'Versturen naar',
      cc: 'cc',
      bcc: 'bcc',
    },
  },
  documents: {
    file_name: 'Bestandsnaam',
    category: 'Categorie',
    upload_date: 'Upload datum',
    last_update: 'Laatste update',
    uploaded_by: 'Geüpload door',
    upload: 'Uploaden',
    download_file: 'Download 1 bestand',
    remove_file: 'Verwijder 1 bestand',
    download_files: 'Download {count} bestanden',
    remove_files: 'Verwijder {count} bestanden',
    click_to_upload_or_drag_and_drop: 'Klik om te uploaden of sleep bestanden',
    maximum_file_size: 'Maximale bestandsgrootte 50 MB',
    all_files: 'Alle bestanden',
    your_files: 'Jouw bestanden',
  },
  salutation: {
    mr: 'De heer',
    ms: 'Mevrouw',
    unspecified: 'Niet gespecificeerd',
  },
  facility_types: {
    working_capital: 'Werkkapitaal',
    loan: 'Lening',
    og_loan: 'OG-lening',
    rc: 'RC',
    factoring: 'Factoring',
    lease: 'Lease',
  },

  email: {
    offer_message:
      'Beste {company},\n\nZoals reeds besproken sturen wij u hierbij met veel plezier onze offerte toe. Wij zijn benieuwd naar uw reactie hierop.\nMocht u vragen hebben, dan beantwoorden wij deze graag.\n\nMet vriendelijke groet,\n{user}',
    credit_proposal_message:
      'Beste commissieleden,\n\nHierbij de stukken van {company} voor het kredietcomité. Ik zal een agendaverzoek sturen.\n\nGr,\n{user}',
  },
  languages: {
    english: 'Engels',
    dutch: 'Nederlands',
  },
  constants: {
    status: {
      approved: 'Geaccepteerd',
      conditionally: 'Voorwaardelijk',
      rejected: 'Afgewezen',
      reset: 'Opnieuw zetten',
      removed: 'Verwijderd',
    },
    lead_channels: {
      intermediair: 'Tussenpersoon',
      worth_of_mouth_advertising: 'Mond-tot-mond reclame',
      our_website: 'Onze website',
      internet_search_engine: 'Zoekmachine internet',
      facebook: 'Facebook',
      instagram: 'Instragram',
      by_customer: 'Via klant',
    },
    job_titles: {
      director: 'Directeur',
      director_and_shareholder: 'Directeur/Aandeelhouder',
      administration: 'Administratie',
    },
    contact_types: {
      collections_and_operations: 'Collections en Operations',
      client_management: 'Clientenbeheer',
      management: 'Directie',
      misc: 'Diverse',
    },
    categories: {
      commerce: 'Commercie',
      offer: 'Offerte',
      credit_proposal: 'Kredietvoorstel',
      miscellaneous: 'Overige',
    },
  },
  information_messages: {
    evaluations_have_been_reset: 'Beoordelingen zijn opnieuw gezet',
    started_a_new_evaluation_commission: '{name} heeft een nieuwe beoordelingscommissie opgezet',
  },
  vuelidate: {
    error: {
      phone_or_mobile_or_email_is_required: 'Telefoon of mobiel of email is verplicht',
    },
  },
  toast: {
    success: {
      contact_person_successfully_deleted: 'De contactpersoon is succesvol verwijderd',
      documents_successfully_deleted: 'De documenten zijn succesvol verwijderd',
      document_successfully_deleted: 'Het document is succesvol verwijderd',
      organogram_successfully_created: 'Het organogram is succesvol aangemaakt',
      organogram_successfully_updated: 'Het organogram is succesvol bijgewerkt',
      contact_person_successfully_created: 'Contactpersoon is succesvol aangemaakt',
      contact_person_successfully_updated: 'Contactpersoon is succesvol bijgewerkt',
      note_successfully_created: 'Notitie is succesvol aangemaakt',
      note_successfully_updated: 'Notitie is succesvol bijgewerkt',
      company_successfully_created: 'Bedrijf is succesvol aangemaakt',
      company_successfully_updated: 'Bedrijf is succesvol bijgewerkt',
      new_logbook_entry_successfully_created: 'Nieuwe logboek entry is succesvol aangemaakt',
      status_successfully_updated: 'Status is succesvol bijgewerkt',
      annual_figures_successfully_created: 'De jaarcijfers zijn succesvol aangemaakt',
      annual_figures_successfully_updated: 'De jaarcijfers zijn succesvol bijgewerkt',
      customer_data_successfully_created: 'De stamgegevens zijn succesvol aangemaakt',
      customer_data_successfully_updated: 'De stamgegevens zijn succesvol bijgewerkt',
      credit_proposal_details_successfully_created: 'De kredietvoorstel details zijn succesvol aangemaakt',
      credit_proposal_details_successfully_updated: 'De kredietvoorstel details zijn succesvol bijgewerkt',
      credit_proposal_evaluation_successfully_requested: 'De kredietvoorstel beoordeling is succesvol aangevraagd',
      credit_proposal_evaluations_successfully_submitted:
        'De kredietvoorstel beoordelingen zijn succesvol definitief gemaakt',
      credit_proposal_evaluations_successfully_reset: 'De kredietvoorstel beoordelingen zijn succesvol opnieuw gezet',
      credit_proposal_notes_successfully_created: 'De kredietvoorstel notulen zijn succesvol aangemaakt',
      credit_proposal_notes_successfully_updated: 'De kredietvoorstel notulen zijn succesvol bijgewerkt',
      creditor_analyses_successfully_created: 'De crediteur analyse is succesvol aangemaakt',
      creditor_analyses_successfully_updated: 'De crediteur analyse is succesvol bijgewerkt',
      debtor_analyses_successfully_updated: 'De debiteur analyse is succesvol bijgewerkt',
      intermediary_successfully_updated: 'De tussenpersoon is succesvol bijgewerkt',
      intermediary_successfully_deleted: 'De tussenpersoon is succesvol verwijderd',
      offer_document_format_successfully_updated: 'De opmaak van het offerte document is succesvol bijgewerkt',
      offer_details_successfully_updated: 'De offerte details zijn succesvol bijgewerkt',
      offer_certainties_successfully_updated: 'De offerte zekerheden zijn succesvol bijgewerkt',
      offer_required_documents_successfully_updated: 'De offerte bij te voegen bescheiden zijn succesvol bijgewerkt',
      offer_document_successfully_saved: 'Het offerte document is successvol opgeslagen',
      offer_email_successfully_send: 'De offerte email is succesvol verstuurd',
      documents_successfully_created: 'De documenten zijn succesvol aangemaakt',
      documents_successfully_updated: 'De documenten zijn succesvol bijgewerkt',
    },
    error: {
      something_went_wrong_while: 'Er is iets misgegaan tijdens het',
      retrieving_required_documents:
        '@:toast.error.something_went_wrong_while ophalen van bij te voegen bescheiden van de offerte',
      deleting_contact_person: '@:toast.error.something_went_wrong_while verwijderen van de contactpersoon',
      deleting_documents: '@:toast.error.something_went_wrong_while verwijderen van de documenten',
      deleting_document: '@:toast.error.something_went_wrong_while verwijderen van het document',
      creating_new_logbook_entry: '@:toast.error.something_went_wrong_while aanmaken van een nieuwe logboek entry',
      updating_status: '@:toast.error.something_went_wrong_while bijwerken van de status',
      creating_the_contact_person: '@:toast.error.something_went_wrong_while aanmaken van de contactpersoon',
      updating_the_contact_person: '@:toast.error.something_went_wrong_while bijwerken van de contactpersoon',
      creating_organogram: '@:toast.error.something_went_wrong_while aanmaken van het organogram',
      updating_organogram: '@:toast.error.something_went_wrong_while bijwerken van het organogram',
      creating_company: '@:toast.error.something_went_wrong_while aanmaken van het bedrijf',
      updating_company: '@:toast.error.something_went_wrong_while bijwerken van het bedrijf',
      creating_intermediary: '@:toast.error.something_went_wrong_while aanmaken van de tussenpersoon',
      creating_note: '@:toast.error.something_went_wrong_while aanmaken van de notitie',
      updating_note: '@:toast.error.something_went_wrong_while bijwerken van de notitie',
      creating_annual_figures: '@:toast.error.something_went_wrong_while aanmaken van de jaarcijfers',
      updating_annual_figures: '@:toast.error.something_went_wrong_while bijwerken van de jaarcijfers',
      retrieving_annual_figures: '@:toast.error.something_went_wrong_while ophalen van de jaarcijfers',
      creating_documents: '@:toast.error.something_went_wrong_while aanmaken van de documenten',
      updating_documents: '@:toast.error.something_went_wrong_while bijwerken van de documenten',
      retrieving_documents: '@:toast.error.something_went_wrong_while ophalen van de documenten',
      downloading_document: '@:toast.error.something_went_wrong_while downloaden van het document',
      downloading_documents: '@:toast.error.something_went_wrong_while downloaden van de documenten',
      creating_customer_data: '@:toast.error.something_went_wrong_while aanmaken van de klantgegevens',
      updating_customer_data: '@:toast.error.something_went_wrong_while bijwerken van de klantgegevens',
      retrieving_customer_data: '@:toast.error.something_went_wrong_while ophalen van de klantgegevens',
      retrieving_prospect_data: '@:toast.error.something_went_wrong_while ophalen van de prospectgegevens',
      retrieving_intermediary: '@:toast.error.something_went_wrong_while ophalen van de tussenpersoon',
      retrieving_intermediaries: '@:toast.error.something_went_wrong_while ophalen van de tussenpersonen',
      retrieving_organogram: '@:toast.error.something_went_wrong_while ophalen van het organogram',
      retrieving_company: '@:toast.error.something_went_wrong_while ophalen van het bedrijf',
      updating_credit_proposal_details:
        '@:toast.error.something_went_wrong_while bijwerken van de kredietvoorstel details',
      creating_credit_proposal_details:
        '@:toast.error.something_went_wrong_while aanmaken van de kredietvoorstel details',
      retrieving_credit_proposal_details:
        '@:toast.error.something_went_wrong_while ophalen van de details van het kredietvoorstel',
      creating_credit_proposal_evaluations:
        '@:toast.error.something_went_wrong_while aanmaken van de kredietvoorstel beoordelingen',
      submitting_credit_proposal_evaluations:
        '@:toast.error.something_went_wrong_while definitief maken van de kredietvoorstel beoordelingen',
      creating_credit_proposal_evaluation:
        '@:toast.error.something_went_wrong_while aanmaken van de kredietvoorstel beoordeling',
      sending_credit_proposal_to_commission:
        '@:toast.error.something_went_wrong_while versturen van het kredietvoorstel naar de commissie',
      creating_credit_proposal_evaluation_comment:
        '@:toast.error.something_went_wrong_while aanmaken van de kredietvoorstel beoordeling comment',
      retrieving_credit_proposal_assessment_committee:
        '@:toast.error.something_went_wrong_while ophalen van de beoordeling commissie van het kredietvoorstel',
      updating_credit_proposal_notes:
        '@:toast.error.something_went_wrong_while bijwerken van de kredietvoorstel notulen',
      creating_credit_proposal_notes:
        '@:toast.error.something_went_wrong_while aanmaken van de kredietvoorstel notulen',
      retrieving_credit_proposal_preview:
        '@:toast.error.something_went_wrong_while ophalen van de kredietvoorstel preview',
      retrieving_credit_proposal_notes:
        '@:toast.error.something_went_wrong_while ophalen van de notulen van het kredietvoorstel',
      retrieving_creditsafe_companies: '@:toast.error.something_went_wrong_while ophalen van de creditsafe bedrijven',
      retrieving_creditsafe_company: '@:toast.error.something_went_wrong_while ophalen van het creditsafe bedrijf',
      creating_creditor_analyses: '@:toast.error.something_went_wrong_while aanmaken van de crediteuren analyses',
      updating_creditor_analyses: '@:toast.error.something_went_wrong_while bijwerken van de crediteuren analyses',
      retrieving_creditor_analyses: '@:toast.error.something_went_wrong_while ophalen van de crediteuren analyses',
      updating_debtor_analyses: '@:toast.error.something_went_wrong_while bijwerken van de debiteuren analyses',
      retrieving_debtor_analyses: '@:toast.error.something_went_wrong_while ophalen van de debiteuren analyses',
      updating_intermediary: '@:toast.error.something_went_wrong_while bijwerken van de tussenpersoon',
      deleting_intermediary: '@:toast.error.something_went_wrong_while verwijderen van de tussenpersoon',
      updating_offer_document: '@:toast.error.something_went_wrong_while bijwerken van het offerte document',
      updating_offer_document_format:
        '@:toast.error.something_went_wrong_while bijwerken van opmaak document van de offerte',
      updating_offer_details: '@:toast.error.something_went_wrong_while bijwerken van de offerte details',
      updating_offer_certainties: '@:toast.error.something_went_wrong_while bijwerken van de offerte zekerheden',
      updating_offer_required_documents:
        '@:toast.error.something_went_wrong_while bijwerken van bij te voegen bescheiden van de offerte',
      retrieving_offer_certainties: '@:toast.error.something_went_wrong_while ophalen van de zekerheden van de offerte',
      retrieving_offer_document_format:
        '@:toast.error.something_went_wrong_while ophalen van opmaak document van de offerte',
      retrieving_offer_details: '@:toast.error.something_went_wrong_while ophalen van de offerte details',
      retrieving_offer_preview: '@:toast.error.something_went_wrong_while ophalen van de offerte preview',
      sending_offer_email: '@:toast.error.something_went_wrong_while versturen van de offerte email',
      logging_out: '@:toast.error.something_went_wrong_while uitloggen',
    },
  },
};
