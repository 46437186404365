<template>
  <template v-if="data.customer_status_id !== 6 && !data.abort_status">
    <LabelSelect
      v-model="data.customer_status_id"
      :errors="errorMessages['customer_status_id']"
      :label-content="$t('ui.status')"
      :label-columns="labelColumns"
      :input-columns="inputColumns"
      :options="computedStatusList"
      wrapper-class="mt-4 pt-2"
      field-name="name"
      @change="setStatusTitles"
    />
    <LabelSelect
      v-model="data.customer_status_title_id"
      :errors="errorMessages['customer_status_title_id']"
      :label-content="$t('sales.list.progress')"
      :label-columns="labelColumns"
      :input-columns="inputColumns"
      :options="data.status_titles"
      field-name="name"
    />
    <LabelSelect
      v-model="data.action"
      :label-content="$t('sales.log.action')"
      :label-columns="labelColumns"
      :input-columns="inputColumns"
      :options="EventConstants"
    />
  </template>
  <LabelTextarea
    v-model="data.title"
    class="row-height"
    :label-content="$t('sales.progress_and_actions.message')"
    :label-columns="labelColumns"
    :input-columns="inputColumns"
    rows="1"
  />
  <LabelToggleSwitch
    v-model="showAlert"
    :label-content="$t('sales.progress_and_actions.add_alert')"
    :label-columns="labelColumns"
    :input-columns="inputColumns"
    class="mt-2 mb-2"
  />
  <div v-show="showAlert">
    <LabelInput
      v-model="data.execution_date"
      :label-content="$t('sales.progress_and_actions.execute_alert_on')"
      :label-columns="labelColumns"
      :input-columns="inputColumns"
      type="datetime-local"
    />
    <EnumSelect
      v-model="data.user_id"
      :label="$t('sales.progress_and_actions.assign_alert_to')"
      :label-columns="labelColumns"
      :input-columns="inputColumns"
      enum-name="users"
      with-empty
    />
    <LabelTextarea
      v-model="data.description"
      class="row-height"
      :label-content="$t('sales.progress_and_actions.alert_message')"
      :label-columns="labelColumns"
      :input-columns="inputColumns"
      rows="1"
    />
  </div>
  <BaseRow>
    <BaseColumn class="col-9">
      <FormFooter
        class="footer-secondary justify-content-end pt-3"
        :pending="loading"
        :disabled="_.isEqual(data, originalData) || loading"
        @save="handleSave"
        @cancel="handleCancel"
      />
    </BaseColumn>
  </BaseRow>
</template>

<script setup>
import LabelInput from '@/components/form/LabelInput.vue';
import LabelTextarea from '@/components/form/LabelTextarea.vue';
import LabelSelect from '@/components/form/LabelSelect.vue';
import EnumSelect from '@/components/form-controls/EnumSelect.vue';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import EventConstants from '@/configs/constants/Events';
import apiClient from '@/services/ApiClient';
import { useAuthorizationStore } from '@/stores/authorization';
import { useSetOriginalProspectRecord, useSetProspectRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import { TRANSLATED_CUSTOMER_STATUS } from '@/configs/constants/CustomerStatus';
import LabelToggleSwitch from '@/components/form/LabelToggleSwitch.vue';
import { BaseColumn, BaseRow } from '@impact-factoring/impact-branding';
import { ref, onBeforeMount, computed } from 'vue';
import { nanoid } from 'nanoid';
import { useI18n } from 'vue-i18n';
import { requiredIf } from '@vuelidate/validators';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['save', 'beforeMount']);

const labelColumns = 'col-xl-3 col-12';
const inputColumns = 'col-xl-6 col-12';
const { t } = useI18n();
const authorizationStore = useAuthorizationStore();

const record = ref(structuredClone(props.initialRecord));
const originalData = ref({});
const validationRules = ref({});
const errorMessages = ref([]);
const loading = ref(false);
const formId = nanoid();
const showAlert = ref(false);

const data = ref({
  status_titles: null,
  customer_status_id: props.initialRecord.customer_status_id,
  customer_status_title_id: props.initialRecord.customer_status_title_id,
  abort_status: props.initialRecord.abort_status,
  action: null,
  title: null,
  user_id: null,
  execution_date: null,
  description: null,
  current_user_id: authorizationStore.user.id,
});

const logbookValidationRules = computed(() => {
  return {
    customer_status_title_id: { requiredIf: requiredIf(hasStatusTitleAndIsNotStepSix.value) },
  };
});

const hasStatusTitleAndIsNotStepSix = computed(
  () => !!(!data.value.customer_status_title_id && data.value.customer_status_id !== 6)
);

const computedStatusList = computed(() => {
  return record.value.statuses.map((status) => {
    return { id: status.data.id, name: TRANSLATED_CUSTOMER_STATUS[status.data.type] };
  });
});

onBeforeMount(async () => {
  emit('beforeMount', formId);

  data.value.status_titles = getStatusTitles();
  const index = Object.values(data.value.status_titles).findIndex(
    (title) => title.id === data.value.customer_status_title_id
  );
  if (index === -1) {
    data.value.customer_status_title_id = 0;
  }

  data.value = useSetProspectRecord(data.value, formId);
  originalData.value = useSetOriginalProspectRecord(data.value, formId);
});

async function handleSave() {
  if (_.isEqual(data.value, originalData.value) || loading.value) {
    return;
  }

  if (data.value.customer_status_title_id === 0) {
    data.value.customer_status_title_id = null;
  }

  validationRules.value = useMergeDefaultVuelidateValidationRules(logbookValidationRules.value, data.value);
  errorMessages.value = await useVuelidateValidation(logbookValidationRules.value, data.value);

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  try {
    loading.value = true;

    if (data.value.customer_status_id === 6) {
      data.value.customer_status_title_id = 23;
    }

    const response = await saveLogbook();
    useSetToast('success', t('toast.success.new_logbook_entry_successfully_created'));
    emit('save', response);

    originalData.value.customer_status_title_id = response.data.customer_status_title_id;
    originalData.value.customer_status_id = response.data.customer_status_id;
    originalData.value.abort_status = response.data.abort_status;
    originalData.value.status_titles = getStatusTitles();

    data.value = useSetProspectRecord(originalData.value, formId);
    originalData.value = useSetOriginalProspectRecord(data.value, formId);

    showAlert.value = false;
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast('error', t('toast.error.creating_new_logbook_entry') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while creating logbook record: ', error);
  } finally {
    loading.value = false;
  }
}
function handleCancel() {
  data.value = useSetProspectRecord(originalData.value, formId);
  errorMessages.value = [];
}

async function saveLogbook() {
  const response = await apiClient.request('put', `/ifapi/sales_statuses/${record.value.id}/logbook`, null, data.value);

  return { response: response, data: data.value };
}

function setStatusTitles() {
  data.value.status_titles = getStatusTitles();
  if (originalData.value.customer_status_id === data.value.customer_status_id) {
    data.value.customer_status_title_id = originalData.value.customer_status_title_id;
    return;
  }
  data.value.customer_status_title_id = 0;
}
function getStatusTitles() {
  return props.initialRecord.status_titles.filter((statusTitle) => {
    return statusTitle.customer_status_id === data.value.customer_status_id;
  });
}
</script>
