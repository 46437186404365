<template>
  <BaseModal
    v-if="showConfirmCreditProposalDataModal && creditProposalUpdateDataNotAllowed"
    :show="showConfirmCreditProposalDataModal"
    :modal-title="$t('ui.not_allowed')"
    size="md"
    custom-footer
    @confirm="closeCreditProposalModal"
    @close="closeCreditProposalModal"
  >
    {{ $t('modal.not_allowed_to_update_credit_proposal_data_while_ongoing_evaluation') }}
  </BaseModal>
  <div class="color-header mb-1">
    {{ t('sales.debtor_analyses.accounts_receivable_balance') }}
  </div>
  <div class="debtor-analyse-table-top-input mb-4 pb-1">
    <AmountInput
      v-model="record.total_debtors_amount"
      :errors="errorMessages['total_debtors_amount']"
      :inner-label="record.sales_status.currency"
      wrapper-class="impact-default-group-margin"
      @enter="handleTotalBalancePercentageSaveOnEnter"
      @blur="setAllTotalBalancePercentageRules"
    />
  </div>
  <ResponsiveTable
    :headers="record.debtor_analyse_rules.data.length ? headers : []"
    borderless
    hoverable
    class="debtor-analyses-table"
  >
    <template v-if="record.debtor_analyse_rules.data.length">
      <tr
        v-for="(debtorAnalyseRule, key) in record.debtor_analyse_rules.data"
        :key="key"
      >
        <td>
          <BaseInput
            v-model="debtorAnalyseRule.debtor_name"
            :errors="errorMessages[`debtor_analyse_rules.data.${key}.debtor_name`]"
            @enter="handleSave"
          />
        </td>
        <td>
          <CompanyRegistrationNumberNumberInput
            v-model="debtorAnalyseRule.company_registration_number"
            :errors="errorMessages[`debtor_analyse_rules.data.${key}.company_registration_number`]"
            @enter="handleSave"
          />
        </td>
        <td>
          <SearchInput
            v-model="debtorAnalyseRule.country_id"
            field-name="id"
            :errors="errorMessages[`debtor_analyse_rules.data.${key}.country_id`]"
            :entities="getCountries"
          />
        </td>
        <td>
          <NumberInput
            v-model="debtorAnalyseRule.grade_score"
            :errors="errorMessages[`debtor_analyse_rules.data.${key}.grade_score`]"
            @blur="blurGradeScore(debtorAnalyseRule)"
            @enter="handleGradeScoreSaveOnEnter(debtorAnalyseRule)"
          />
        </td>
        <td>
          <NumberInput
            v-model="debtorAnalyseRule.creditsafe_score"
            :errors="errorMessages[`debtor_analyse_rules.data.${key}.creditsafe_score`]"
            @blur="blurCreditSafeScore(debtorAnalyseRule)"
            @enter="handleCreditsafeScoreSaveOnEnter(debtorAnalyseRule)"
          />
        </td>
        <td>
          <EnumSelect
            v-model="debtorAnalyseRule.insurer_id"
            :errors="errorMessages[`debtor_analyse_rules.data.${key}.insurer_id`]"
            enum-name="insurers"
          />
        </td>
        <td>
          <AmountInput
            v-model="debtorAnalyseRule.total_amount"
            :errors="errorMessages[`debtor_analyse_rules.data.${key}.total_amount`]"
            :inner-label="record.sales_status.currency"
            @blur="blurTotalAmountRule(debtorAnalyseRule)"
            @enter="handleTotalAmountSaveOnEnter(debtorAnalyseRule)"
          />
        </td>
        <td>
          <AmountInput
            v-model="debtorAnalyseRule.limit_amount"
            :errors="errorMessages[`debtor_analyse_rules.data.${key}.limit_amount`]"
            :inner-label="record.sales_status.currency"
            @blur="blurLimitAmountRule(debtorAnalyseRule)"
            @enter="handleLimitAmountSaveOnEnter(debtorAnalyseRule)"
          />
        </td>
        <td class="table-text-spacing-left hover-background">
          <template v-if="debtorAnalyseRule.non_financiable_amount">
            {{ numberFormatter(debtorAnalyseRule.non_financiable_amount) }}
          </template>
        </td>
        <td class="table-text-spacing-left hover-background">
          <template v-if="debtorAnalyseRule.financiable_amount">
            {{ numberFormatter(debtorAnalyseRule.financiable_amount) }}
          </template>
        </td>
        <td class="hover-background">
          {{ debtorAnalyseRule.total_balance_percentage }}
        </td>
        <td class="grow-textarea-on-focus">
          <BaseTextarea
            v-model="debtorAnalyseRule.memo"
            class="grow-on-focus"
            rows="1"
          />
        </td>
        <td class="icons-column">
          <img
            src="/img/creditsafe-logo.png"
            alt="creditsafe icon"
            @click="openSearchCreditsafeModal(debtorAnalyseRule, key)"
          />
          <div @click="removeDebtorAnalyseRule(debtorAnalyseRule, key)">
            <i
              class="fa-regular fa-trash-can md"
              aria-hidden="true"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td />
        <td />
        <td />
        <td />
        <td />
        <td class="table-footer-th">
          <div class="fw-medium mt-3 ps-2">
            {{ t('ui.numbers.total') }}
          </div>
        </td>
        <td class="table-footer-th">
          <template v-if="record.total_amount">
            <div class="fw-medium text-end mt-3 pe-2">{{ numberFormatter(record.total_amount) }}</div>
          </template>
        </td>
        <td class="table-footer-th">
          <template v-if="record.total_limit_amount">
            <div class="fw-medium mt-3 ps-2">{{ numberFormatter(record.total_limit_amount) }}</div>
          </template>
        </td>
        <td class="table-text-spacing-left table-footer-th">
          <template v-if="record.total_non_financiable_amount">
            <div class="fw-medium mt-3">
              {{ numberFormatter(record.total_non_financiable_amount) }}
            </div>
          </template>
        </td>
        <td class="table-text-spacing-left table-footer-th">
          <template v-if="record.total_financiable_amount">
            <div class="fw-medium mt-3">
              {{ numberFormatter(record.total_financiable_amount) }}
            </div>
          </template>
        </td>
        <td class="table-footer-th">
          <div class="fw-medium mt-3">
            {{ record.total_balance_percentage }}
          </div>
        </td>
      </tr>
    </template>
    <template #table-footer>
      <FormIconButton
        icon="fa-solid fa-plus"
        button-style="primary"
        :label-right="$t('sales.debtor_analyses.debtor')"
        class="mb-3"
        @click.prevent="addDebtor"
      />
      <FormIconButton
        icon="fa-solid fa-plus"
        button-style="primary"
        :label-right="$t('sales.debtor_analyses.retrieve_debtor')"
        class="mb-3 ms-3"
        @click.prevent="openSearchCreditsafeModal"
      />
    </template>
  </ResponsiveTable>
  <FormFooter
    class="mt-auto"
    :pending="loading"
    :disabled="_.isEqual(record, originalRecord) || loading"
    @save="handleSave"
    @cancel="handleCancel"
  />
  <CreditsafeSearchDebtorModal
    v-if="showCreditsafeSearchModal"
    :initial-record="creditsafeSearchData"
    :initial-companies="foundCreditsafeCompanies"
    :show-next-button="!!creditsafeConfirmData && !!foundCreditsafeCompanies.length"
    @close="closeSearchCreditsafeModal"
    @next="toggleToConfirmCreditsafeModal"
    @load-company="setCreditsafeCompany"
    @load-companies="setCreditsafeCompanies"
  />
  <CreditsafeConfirmDebtorModal
    v-if="creditsafeConfirmData"
    :show="showCreditsafeConfirmModal"
    :initial-record="creditsafeConfirmData"
    @close="closeConfirmCreditsafeModal"
    @previous="toggleToSearchCreditsafeModal"
    @submit="updateDebtorAnalyseRule"
  />
</template>

<script setup>
import FormIconButton from '@/components/buttons/FormIconButton.vue';
import ResponsiveTable from '@/components/tables/ResponsiveTable.vue';
import AmountInput from '@/components/form-controls/AmountInput.vue';
import BaseTextarea from '@/components/form/BaseTextarea.vue';
import NumberInput from '@/components/form/NumberInput.vue';
import EnumSelect from '@/components/form-controls/EnumSelect.vue';
import SearchInput from '@/components/form-controls/SearchInput/SearchInput.vue';
import CompanyRegistrationNumberNumberInput from '@/components/form-controls/CompanyRegistrationNumberNumberInput.vue';
import apiClient from '@/services/ApiClient';
import BaseInput from '@/components/form/BaseInput.vue';
import { formatNumber } from '@/modules/formatters';
import { useEnumerationsStore } from '@/stores/enumerations';
import CreditsafeSearchDebtorModal from '@/forms/sales/sales-status/creditsafe/CreditsafeSearchDebtorModal.vue';
import CreditsafeConfirmDebtorModal from '@/forms/sales/sales-status/creditsafe/CreditsafeConfirmDebtorModal.vue';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import {
  useAmountSuffixConvertAmountToCents,
  useAmountSuffixConvertCentsToAmount,
} from '@/composables/UseNumberManipulation';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import { useBeforeRouteLeave, useSetOriginalRecord, useSetRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import { APPROVED, EVALUATION } from '@/configs/constants/Status';
import {
  useCompareSalesStatusObject,
  useSetAndMergeSalesStatusObject,
} from '@/composables/records/UseAssessmentCommittee';
import BaseModal from '@/components/BaseModal.vue';
import {
  useGetProspectCreditProposalOwner,
  useGetProspectCreditProposalStatus,
} from '@/composables/UseProspectViewData';
import { computed, onBeforeMount, ref } from 'vue';
import { minValue, maxValue, required, requiredIf } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import { nanoid } from 'nanoid';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
    required: true,
  },
});
const emit = defineEmits(['save']);

const { t } = useI18n();
const enums = useEnumerationsStore();

const headers = [
  t('sales.debtor_analyses.debtor_name'),
  t('general.company_registration_number'),
  t('address.country'),
  t('sales.debtor_analyses.grade'),
  t('sales.debtor_analyses.creditsafe_score_shorthand'),
  t('sales.debtor_analyses.insurer'),
  t('sales.debtor_analyses.total_amount'),
  t('sales.debtor_analyses.limit'),
  t('sales.debtor_analyses.unfinanceable'),
  t('sales.debtor_analyses.financeable'),
  '%',
  t('sales.debtor_analyses.particularities'),
];

const record = ref(props.initialRecord);
const originalRecord = ref({});
const validationRules = ref(null);
const errorMessages = ref([]);
const loading = ref(false);
const formId = nanoid();
const showCreditsafeSearchModal = ref(false);
const showCreditsafeConfirmModal = ref(false);
const creditsafeSearchData = ref(null);
const creditsafeConfirmData = ref(null);
const foundCreditsafeCompanies = ref([]);
const clickedDebtorAnalyseRuleKey = ref(null);
const showConfirmCreditProposalDataModal = ref(false);
const creditProposalUpdateDataNotAllowed =
  useGetProspectCreditProposalStatus() === EVALUATION && !useGetProspectCreditProposalOwner();
let originalCreditProposalObject = {};

const formatOptions = {
  style: 'decimal',
  minimumFractionDigits: 2,
};

const rules = computed(() => {
  return createRulesObject();
});

const getCountries = computed(() => {
  const countries = {};
  enums.data.countries.map((country) => {
    countries[country.id] = country.translation;
  });
  return countries;
});

onBeforeMount(async () => {
  useBeforeRouteLeave();

  record.value.debtor_analyse_rules.data.forEach((rule) => {
    blurTotalAmountRule(rule);
    blurLimitAmountRule(rule);
  });

  record.value = useSetRecord(record.value, formId);
  originalRecord.value = useSetOriginalRecord(record.value, formId);

  if (creditProposalUpdateDataNotAllowed) {
    originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
  }

  validationRules.value = useMergeDefaultVuelidateValidationRules(rules.value, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);
});

async function handleSave() {
  record.value.debtor_analyse_rules.data.forEach((rule) => {
    blurTotalAmountRule(rule);
    blurLimitAmountRule(rule);
  });

  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  if (creditProposalUpdateDataNotAllowed) {
    const latestCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));

    const creditProposalHasDataDifference = useCompareSalesStatusObject(
      originalCreditProposalObject,
      latestCreditProposalObject
    );

    if (
      creditProposalHasDataDifference ||
      originalCreditProposalObject.debtor_analyses.debtor_analyse_rules.length !==
        record.value.debtor_analyse_rules.data.length
    ) {
      showConfirmCreditProposalDataModal.value = true;
      return;
    }
  }

  validationRules.value = useMergeDefaultVuelidateValidationRules(rules.value, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  await save();
}

async function save() {
  try {
    loading.value = true;
    const recordCopy = structuredClone(record.value);

    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${record.value.sales_status_id}/debtor_analyses`,
      {
        include: ['sales_status.customer_log.task', 'sales_status.customer_log.customer_status_title'],
      },
      useAmountSuffixConvertAmountToCents(recordCopy)
    );
    useSetToast('success', t('toast.success.debtor_analyses_successfully_updated'));
    emit('save', { customerLogs: response.sales_status.customer_log.data });

    record.value.debtor_analyse_rules.data = useAmountSuffixConvertCentsToAmount(response.debtor_analyse_rules);
    record.value.debtor_analyse_rules.data.forEach((rule) => {
      blurTotalAmountRule(rule);
      blurLimitAmountRule(rule);
    });

    originalRecord.value = useSetOriginalRecord(record.value, formId);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    record.value = useAmountSuffixConvertCentsToAmount(record.value);
    useSetToast('error', t('toast.error.updating_debtor_analyses') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while updating debtor analyses: ', error);
  } finally {
    loading.value = false;
  }
}
function handleCancel() {
  record.value = useSetRecord(originalRecord.value, formId);
  if (creditProposalUpdateDataNotAllowed) {
    originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
  }
  errorMessages.value = [];
}

function handleTotalBalancePercentageSaveOnEnter() {
  setAllTotalBalancePercentageRules();
  handleSave();
}

function handleGradeScoreSaveOnEnter(debtorAnalyseRule) {
  blurGradeScore(debtorAnalyseRule);
  handleSave();
}

function handleCreditsafeScoreSaveOnEnter(debtorAnalyseRule) {
  blurCreditSafeScore(debtorAnalyseRule);
  handleSave();
}

function handleTotalAmountSaveOnEnter(debtorAnalyseRule) {
  blurTotalAmountRule(debtorAnalyseRule);
  handleSave();
}

function handleLimitAmountSaveOnEnter(debtorAnalyseRule) {
  blurLimitAmountRule(debtorAnalyseRule);
  handleSave();
}

async function removeDebtorAnalyseRule(debtorAnalyseRule, key) {
  record.value.debtor_analyse_rules.data.splice(key, 1);

  if (Object.entries(errorMessages.value).length) {
    validationRules.value = useMergeDefaultVuelidateValidationRules(rules.value, record.value);
    errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);
  }

  blurTotalAmountRule(debtorAnalyseRule);
  blurLimitAmountRule(debtorAnalyseRule);
  setTotalAmountTotalSum();
}

function openSearchCreditsafeModal(debtorAnalyseRule, key) {
  foundCreditsafeCompanies.value = [];
  if (key || key === 0) {
    clickedDebtorAnalyseRuleKey.value = key;
    creditsafeSearchData.value = debtorAnalyseRule;
  } else {
    creditsafeSearchData.value = null;
    clickedDebtorAnalyseRuleKey.value = null;
  }
  toggleShowSearchCreditsafeModal(true);
}

function closeSearchCreditsafeModal() {
  toggleShowSearchCreditsafeModal(false);
  creditsafeConfirmData.value = null;
  creditsafeSearchData.value = null;
  clickedDebtorAnalyseRuleKey.value = null;
  foundCreditsafeCompanies.value = [];
}
function closeConfirmCreditsafeModal() {
  toggleShowConfirmCreditsafeModal(false);
  creditsafeConfirmData.value = null;
  creditsafeSearchData.value = null;
  clickedDebtorAnalyseRuleKey.value = null;
  foundCreditsafeCompanies.value = [];
}

function toggleToSearchCreditsafeModal() {
  toggleShowSearchCreditsafeModal(true);
  toggleShowConfirmCreditsafeModal(false);
}
function toggleToConfirmCreditsafeModal() {
  toggleShowConfirmCreditsafeModal(true);
  toggleShowSearchCreditsafeModal(false);
}
function toggleShowSearchCreditsafeModal(boolean) {
  showCreditsafeSearchModal.value = boolean;
}
function toggleShowConfirmCreditsafeModal(boolean) {
  showCreditsafeConfirmModal.value = boolean;
}

function setCreditsafeCompanies(companies) {
  foundCreditsafeCompanies.value = companies;
}

function setCreditsafeCompany(data) {
  creditsafeConfirmData.value = data.company;
  creditsafeSearchData.value = data.searchModalValues;
  toggleShowSearchCreditsafeModal(false);
  toggleShowConfirmCreditsafeModal(true);
}

function updateDebtorAnalyseRule(data = null) {
  let fetchedCreditSafeData;
  let country = null;
  let debtorAnalyseRule = record.value.debtor_analyse_rules.data[clickedDebtorAnalyseRuleKey.value];

  if (!debtorAnalyseRule) {
    debtorAnalyseRule = addDebtor();
    clickedDebtorAnalyseRuleKey.value = null;
  }

  if (typeof data.country === 'string') {
    country = enums.data.countries.find((country) => country.iso === data.country);
  }

  fetchedCreditSafeData = {
    creditsafe_score: data.creditsafeScore,
    company_registration_number: data.regNo,
    country_id: country?.id,
  };

  const mergedObject = _.mergeWith({}, debtorAnalyseRule, fetchedCreditSafeData, (a, b) => (!b ? a : undefined));

  debtorAnalyseRule.country_id = mergedObject.country_id;
  debtorAnalyseRule.company_registration_number = mergedObject.company_registration_number;
  debtorAnalyseRule.creditsafe_score = mergedObject.creditsafe_score;

  if (debtorAnalyseRule.creditsafe_score) {
    blurCreditSafeScore(debtorAnalyseRule);
  }
  toggleShowConfirmCreditsafeModal(false);
}

function addDebtor() {
  const debtor = {
    id: null,
    debtor_name: null,
    company_registration_number: null,
    country_id: null,
  };

  record.value.debtor_analyse_rules.data = [...record.value.debtor_analyse_rules.data, debtor];
  validationRules.value = useMergeDefaultVuelidateValidationRules(rules.value, record.value);
  return debtor;
}

function createRulesObject() {
  let debtorAnalyseRulesObject = {
    debtor_analyse_rules: {
      data: {},
    },
  };

  record.value.debtor_analyse_rules.data.forEach((debtorAnalyseRule, key) => {
    Object.assign(debtorAnalyseRulesObject.debtor_analyse_rules.data, {
      [key]: {
        total_amount: { required },
        grade_score: { minValue: minValue(1), maxValue: maxValue(10) },
        creditsafe_score: { minValue: minValue(1), maxValue: maxValue(100) },
        debtor_name: { required },
      },
    });
  });

  return {
    total_debtors_amount: {
      minValue: minValue(record.value.total_amount?.toString().replaceAll('.', '').replace(',', '') / 100),
      requiredIf: requiredIf(record.value.debtor_analyse_rules.data.length),
    },
    ...debtorAnalyseRulesObject,
  };
}

function numberFormatter(value) {
  return formatNumber(value.toString().replace('.', ','), formatOptions);
}

function blurGradeScore(debtorAnalyseRule) {
  if (!debtorAnalyseRule.grade_score) {
    blurCreditSafeScore(debtorAnalyseRule);
    return;
  }
  const gradeScore = parseInt(debtorAnalyseRule.grade_score);
  debtorAnalyseRule.limit_amount = getGradeScoreLimitAmount(gradeScore);
  blurLimitAmountRule(debtorAnalyseRule);
}

function blurCreditSafeScore(debtorAnalyseRule) {
  if (debtorAnalyseRule.grade_score || !debtorAnalyseRule.creditsafe_score) {
    return;
  }
  const creditSafeScore = parseInt(debtorAnalyseRule.creditsafe_score);
  debtorAnalyseRule.limit_amount = getCreditSafeScoreLimitAmount(creditSafeScore);
  blurLimitAmountRule(debtorAnalyseRule);
}

function blurTotalAmountRule(debtorAnalyseRule) {
  setTotalAmountTotalSum();
  setNonFinanciableAmountRule(debtorAnalyseRule);
  setNonFinanciableAmountTotalSum();
  setAllTotalBalancePercentageRules();
}
function blurLimitAmountRule(debtorAnalyseRule) {
  setLimitAmountTotalSum();
  setNonFinanciableAmountRule(debtorAnalyseRule);
  setNonFinanciableAmountTotalSum();
  setFinanciableAmountRule(debtorAnalyseRule);
  setFinanciableAmountTotalSum();
}

function getDebtorTotalSumByFieldName(fieldName) {
  const rulesMapped = record.value.debtor_analyse_rules.data.map((debtorAnalyseRule) => {
    return replaceDebtorAnalyseRuleDecimal(debtorAnalyseRule, fieldName);
  });
  const rulesArrayWithoutNull = rulesMapped.filter((rule) => rule !== null);

  if (!rulesArrayWithoutNull.length) {
    return;
  }

  const initialValue = 0;
  let totalAmount = rulesMapped.reduce(
    (accumulator, currentValue) => (accumulator || 0) + (currentValue || 0),
    initialValue
  );

  if ((!totalAmount && totalAmount !== 0) || isNaN(totalAmount)) {
    return;
  }

  return numberFormatter(totalAmount / 100)
    .replaceAll('.', '')
    .replace(',', '.');
}
function replaceDebtorAnalyseRuleDecimal(debtorAnalyseRule, fieldName) {
  let field;
  if (fieldName === 'total_amount') field = debtorAnalyseRule.total_amount;
  if (fieldName === 'limit_amount') field = debtorAnalyseRule.limit_amount;
  if (fieldName === 'non_financiable_amount') field = debtorAnalyseRule.non_financiable_amount;
  if (fieldName === 'financiable_amount') field = debtorAnalyseRule.financiable_amount;

  if (!field) {
    return null;
  }
  return stringWithDecimalSeparatorToInt(field);
}
function stringWithDecimalSeparatorToInt(string) {
  if (!string) {
    return 0;
  }
  return parseInt(string.toString().replaceAll('.', '').replace(',', ''));
}

function setNonFinanciableAmountRule(debtorAnalyseRule) {
  if (!debtorAnalyseRule.total_amount && !debtorAnalyseRule.limit_amount) {
    debtorAnalyseRule.non_financiable_amount = null;
    return;
  }
  let nonFinanciableAmountRule =
    stringWithDecimalSeparatorToInt(debtorAnalyseRule.total_amount) -
    stringWithDecimalSeparatorToInt(debtorAnalyseRule.limit_amount);

  if (nonFinanciableAmountRule.toString().includes('-')) {
    nonFinanciableAmountRule = Math.max(0, nonFinanciableAmountRule) || 0;
  }
  debtorAnalyseRule.non_financiable_amount = numberFormatter(nonFinanciableAmountRule / 100)
    .replaceAll('.', '')
    .replace(',', '.');
}
function setFinanciableAmountRule(debtorAnalyseRule) {
  debtorAnalyseRule.financiable_amount = debtorAnalyseRule.limit_amount;
}
function setAllTotalBalancePercentageRules() {
  let totalBalancePercentageTotalSum = 0;
  record.value.debtor_analyse_rules.data.forEach((debtorAnalyseRule) => {
    let totalBalancePercentageRule;
    if (record.value.total_debtors_amount && debtorAnalyseRule.total_amount) {
      totalBalancePercentageRule = Math.round(
        (stringWithDecimalSeparatorToInt(debtorAnalyseRule.total_amount) /
          stringWithDecimalSeparatorToInt(record.value.total_debtors_amount)) *
          100
      );
    } else {
      totalBalancePercentageRule = null;
    }

    if (isNaN(totalBalancePercentageRule) || !totalBalancePercentageRule) {
      debtorAnalyseRule.total_balance_percentage = null;
    } else {
      debtorAnalyseRule.total_balance_percentage = totalBalancePercentageRule;
    }

    totalBalancePercentageTotalSum += debtorAnalyseRule.total_balance_percentage;
  });
  setTotalBalancePercentageTotalSum(totalBalancePercentageTotalSum);
}

function setTotalAmountTotalSum() {
  record.value.total_amount = getDebtorTotalSumByFieldName('total_amount') || '';
}
function setLimitAmountTotalSum() {
  record.value.total_limit_amount = getDebtorTotalSumByFieldName('limit_amount') || '';
}
function setNonFinanciableAmountTotalSum() {
  record.value.total_non_financiable_amount = getDebtorTotalSumByFieldName('non_financiable_amount') || '';
}
function setFinanciableAmountTotalSum() {
  record.value.total_financiable_amount = getDebtorTotalSumByFieldName('financiable_amount') || '';
}
function setTotalBalancePercentageTotalSum(totalBalancePercentageTotalSum) {
  record.value.total_balance_percentage = parseInt(totalBalancePercentageTotalSum) || null;
}

function getCreditSafeScoreLimitAmount(creditSafeScore) {
  switch (true) {
    case creditSafeScore >= 37:
      return '5000.00';
    default:
      return '0.00';
  }
}
function getGradeScoreLimitAmount(gradeScore) {
  switch (true) {
    case gradeScore <= 5:
      return '25000.00';
    case gradeScore === 6:
    case gradeScore === 7:
      return '15000.00';
    default:
      return '0.00';
  }
}

function getCreditProposalObject() {
  const debtorAnalyses = { debtor_analyses: structuredClone(record.value) };

  debtorAnalyses.debtor_analyses.debtor_analyse_rules = debtorAnalyses.debtor_analyses.debtor_analyse_rules.data;
  delete debtorAnalyses.debtor_analyses.sales_status;

  return { sales_status: debtorAnalyses };
}

function closeCreditProposalModal() {
  showConfirmCreditProposalDataModal.value = false;
}
</script>
