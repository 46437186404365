<template>
  <FormControlWrapper
    v-if="labelContent"
    v-bind="formControlProps"
    :for="labelId"
    :wrapper-class="['dynamic-search-input', props.wrapperClass]"
  >
    <div
      v-click-outside="closeDropdown"
      @keydown.esc="closeDropdown"
    >
      <div
        class="dropdown-arrow"
        :class="{ 'dropdown-arrow-open': showDropdown }"
      >
        <BaseInput
          v-bind="$attrs"
          :id="labelId"
          v-model="computedModelValue"
          :placeholder="placeholder"
          :errors="props.errors"
          :show-error-messages="props.showErrorMessages"
          :class="{
            'fade-in-text': computedModelValue,
          }"
          @focus="onFocus"
          @blur="onBlur"
        />
      </div>
      <div class="position-relative w-100">
        <SearchInputDropdown
          v-if="showDropdown"
          :data="computedSearchResults"
          :initial-selected-item="selectedItem"
          :class="{ 'overflow-auto': computedSearchResults?.length > 8 }"
          class="w-100"
          @clicked-item="handleClickedItem"
        />
      </div>
    </div>
    <slot />
  </FormControlWrapper>
  <div
    v-else
    class="dynamic-search-input"
  >
    <div
      v-click-outside="closeDropdown"
      @keydown.esc="closeDropdown"
    >
      <BaseInput
        v-bind="$attrs"
        :id="labelId"
        v-model="computedModelValue"
        :errors="props.errors"
        :show-error-messages="props.showErrorMessages"
        :placeholder="placeholder"
        :class="{
          'fade-in-text': selectedItem !== computedModelValue,
        }"
        @focus="onFocus"
        @blur="onBlur"
      />
      <div class="position-relative w-100">
        <SearchInputDropdown
          v-if="showDropdown"
          :data="computedSearchResults"
          :initial-selected-item="selectedItem"
          :class="{ 'overflow-auto': computedSearchResults?.length > 8 }"
          class="w-100"
          @clicked-item="handleClickedItem"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import FormControlWrapper from '@/components/form/FormControlWrapper.vue';
import BaseInput from '@/components/form/BaseInput';
import SearchInputDropdown from '@/components/form-controls/SearchInput/SearchInputDropdown.vue';
// import { useFocusFirstNode, useFocusLastNode } from '@/composables/UseHotkeyNodeSelection';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import { nanoid } from 'nanoid';

const props = defineProps({
  entities: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  modelValue: {
    type: [Number, String],
    default: null,
  },
  itemKey: {
    type: Number,
    default: null,
  },
  itemId: {
    type: Number,
    default: null,
  },
  fieldName: {
    type: String,
    default: 'name',
    validator: (val) => ['id', 'name'].includes(val),
  },
  initialPlaceholder: {
    type: String,
    default: '',
  },
  tooltipContent: {
    type: String,
    default: '',
  },
  searchNameAndKey: {
    type: Boolean,
    default: false,
  },
  sortByName: {
    type: Boolean,
    default: true,
  },
  disallowCustomEntry: {
    type: Boolean,
    default: true,
  },
  errors: {
    type: Array,
    default: () => [],
  },
  showErrorMessages: {
    type: Boolean,
    default: true,
  },
  labelContent: {
    type: String,
    default: null,
  },
  labelColumns: {
    type: String,
    default: null,
  },
  inputColumns: {
    type: String,
    default: null,
  },
  wrapperClass: {
    type: [String, Array, Object],
    default: null,
  },
});
const emit = defineEmits(['update:modelValue', 'focus', 'selectItem']);

const { t } = useI18n();
const formControlProps = {
  labelContent: props.labelContent,
  labelColumns: props.labelColumns,
  inputColumns: props.inputColumns,
  tooltipContent: props.tooltipContent,
};

const placeholder =
  props.initialPlaceholder || props.disallowCustomEntry
    ? t('ui.placeholders.search')
    : t('ui.placeholders.search_or_add');

const showDropdown = ref(false);
const originalModelValue = ref(null);
const selectedItem = ref(null);
const labelId = nanoid();

const computedModelValue = computed({
  get() {
    if (!Object.keys(props.entities).length) {
      return props.modelValue;
    }

    if (props.itemId || props.itemId === 0) {
      const item = getSelectedItemById(props.itemId);

      if (item?.name === props.modelValue) {
        return item?.name;
      }
    }

    if (Number.isInteger(props.modelValue)) {
      const item = getSelectedItemById(props.modelValue);
      return item?.name;
    }

    return props.modelValue;
  },
  set(value) {
    if (!value) {
      emit('update:modelValue', null);
      return;
    }
    emit('update:modelValue', value);
  },
});

const computedSearchResults = computed(() => {
  return filteredList();
});

const computedEntities = computed(() => {
  if (!Object.entries(props.entities).length && !props.entities?.length) {
    return props.entities;
  }

  let entities = props.entities;
  if (_.isObject(props.entities) && !Array.isArray(props.entities)) {
    entities = Object.entries(props.entities).map((entry) => {
      return { id: parseInt(entry[0]), name: entry[1] };
    });
  }

  if (props.sortByName) {
    entities = _.sortBy(entities, 'name');
  }

  return entities;
});

function filteredList() {
  if (!computedModelValue.value) {
    return computedEntities.value;
  }
  const filteredArray = [];
  for (let i = 0, cachedLength = computedEntities.value.length; i < cachedLength; ++i) {
    if (
      computedEntities.value[i].name.toLowerCase().includes(computedModelValue.value.toLowerCase()) ||
      (props.searchNameAndKey && computedEntities.value[i].id.toString().includes(computedModelValue.value))
    ) {
      filteredArray.push(computedEntities.value[i]);
    }
  }
  return filteredArray;
}

function handleClickedItem(item) {
  if (selectedItem.value === item?.id) {
    setSelectedItemOnClick(null);
    return;
  }

  if (item?.name && props.fieldName === 'name') {
    setSelectedItemOnClick(item.name);
    closeDropdown();
    return;
  }

  if (item?.id) {
    setSelectedItemOnClick(item.id);
    closeDropdown();
  }
}

function onBlur(event) {
  if (!event?.relatedTarget?.classList.contains('item')) {
    if (!computedModelValue.value) {
      setSelectedItemOnBlur(null);
      return;
    }

    if (computedModelValue.value === originalModelValue.value) {
      return;
    }

    let item;
    if (typeof props.modelValue === 'string') {
      item = getSelectedItemByName(props.modelValue);
    }

    if (item?.id) {
      if (props.fieldName === 'id') {
        computedModelValue.value = item.id;
      } else {
        computedModelValue.value = item?.name || null;
      }
      setSelectedItemOnBlur(item.id);
      return;
    }

    if (props.disallowCustomEntry && (props.itemId || props.itemId === 0)) {
      item = getSelectedItemById(props.itemId);
      setSelectedItemOnBlur(item?.id || null);
      return;
    }

    if (Number.isInteger(props.modelValue)) {
      item = getSelectedItemById(props.modelValue);
      setSelectedItemOnBlur(item?.id || null);
      return;
    }

    if (props.disallowCustomEntry && !item?.id) {
      item = getSelectedItemById(selectedItem.value);

      if (props.fieldName === 'id') {
        computedModelValue.value = item?.id || null;
      } else {
        computedModelValue.value = item?.name || null;
      }
      setSelectedItemOnBlur(item?.id || null);
    }

    closeDropdown();
  }
}

function onFocus() {
  originalModelValue.value = structuredClone(computedModelValue.value);

  if (!computedModelValue.value) {
    setSelectedItemOnFocus(null);
    return;
  }

  if (props.itemId || props.itemId === 0) {
    setSelectedItemOnFocus(props.itemId);
    return;
  }

  if (Number.isInteger(props.modelValue)) {
    setSelectedItemOnFocus(props.modelValue);
    return;
  }

  if (typeof props.modelValue === 'string' && !getSelectedItemByName(props.modelValue)) {
    openDropdown();
    return;
  }

  if (Object.entries(props.entities).length && computedModelValue.value) {
    const item = getSelectedItemByName(computedModelValue.value);
    setSelectedItemOnFocus(item?.id);
  }

  openDropdown();
}

function setSelectedItemOnClick(value) {
  computedModelValue.value = value;
  selectedItem.value = value;
  emit('selectItem', value, props.itemKey);
}

function setSelectedItemOnFocus(value) {
  selectedItem.value = value;
  emit('focus');
  openDropdown();
}

function setSelectedItemOnBlur(value) {
  selectedItem.value = value;
  emit('selectItem', value, props.itemKey);
  closeDropdown();
}

function getSelectedItemByName(value) {
  return computedEntities.value.find((entity) => {
    if (entity.uniqueIdentifier) {
      return entity?.id === selectedItem.value && entity?.name?.toLowerCase() === value?.toLowerCase();
    }
    return entity?.name?.toLowerCase() === value?.toLowerCase();
  });
}

function getSelectedItemById(value) {
  return computedEntities.value.find((entity) => {
    return entity.id === value;
  });
}

function openDropdown() {
  showDropdown.value = true;
}
function closeDropdown() {
  showDropdown.value = false;
}
// function setDropdownItemRefs(refs) {
//   dropdownItemRefs.value = refs;
// }
// function focusFirstDropdownElement(event) {
//   useFocusFirstNode(event, dropdownItemRefs.value);
// }
// function focusLastDropdownElement(event) {
//   useFocusLastNode(event, dropdownItemRefs.value);
// }
</script>
