<template>
  <BaseModal
    v-if="showConfirmCreditProposalDataModal && creditProposalUpdateDataNotAllowed"
    :show="showConfirmCreditProposalDataModal"
    :modal-title="$t('ui.not_allowed')"
    size="md"
    custom-footer
    @confirm="closeCreditProposalModal"
    @close="closeCreditProposalModal"
  >
    {{ $t('modal.not_allowed_to_update_credit_proposal_data_while_ongoing_evaluation') }}
  </BaseModal>
  <ResponsiveTable
    class="offer-certainties-table"
    hoverable
    borderless
  >
    <tr
      v-for="(certainty, key) in record.data"
      :key="key"
    >
      <td class="align-middle check">
        <div class="d-flex">
          <BaseCheckbox
            v-model="certainty.selected"
            :disabled="certainty.certainty_rule_id === 24"
            @click="handleClickCheckbox($event, certainty)"
          />
        </div>
      </td>
      <td
        class="align-middle certainty-fields-wrapper"
        :class="{ 'text-muted fst-italic': !certainty.selected }"
      >
        <template v-if="certainty.type === 'pledge_rights_credit_insurance'">
          <BaseSelect
            v-model="certainty.values.count"
            :errors="errorMessages[`${key}.values.count`]"
            :show-error-messages="false"
            :disabled="!certainty.selected"
            :options="readableCounts"
            :full-width="false"
          />
          {{ splitTitle(certainty.title_certainty)[1] }}
          <EnumSelect
            v-model="certainty.values.insurer.id"
            :errors="errorMessages[`${key}.values.certainty.values.insurer.id`]"
            :show-error-messages="false"
            :disabled="!certainty.selected"
            enum-name="insurers"
            :full-width="false"
          />
          {{ splitTitle(certainty.title_certainty)[2] }}
          <BaseInput
            v-model="certainty.values.insurance_policy_number"
            :errors="errorMessages[`${key}.values.insurance_policy_number`]"
            :show-error-messages="false"
            :disabled="!certainty.selected"
            class="input-policy-number ml-2"
            @enter="handleSave"
          />
        </template>

        <template v-else-if="certainty.type === 'personal_suretyship'">
          {{ splitTitle(certainty.title_certainty)[0] }}
          <SearchInput
            v-model="certainty.values.signers.client[0].persons[0].id"
            field-name="id"
            :errors="errorMessages[`${key}.values.signers.client.0.persons.0.id`]"
            :show-error-messages="false"
            :entities="computedContactPersons"
            :initial-placeholder="$t('ui.placeholders.search_contact_person')"
            :disabled="!certainty.selected"
            class="d-inline-block input-search"
          />
          {{ splitTitle(certainty.title_certainty)[1] }}
          <AmountInput
            v-model="certainty.values.suretyship_amount"
            :errors="errorMessages[`${key}.values.suretyship_amount`]"
            :show-error-messages="false"
            :inner-label="record.sales_status.currency"
            :disabled="!certainty.selected"
            class="input-amount"
            @enter="handleSave"
          />
        </template>

        <template v-else-if="certainty.type === 'business_suretyship'">
          {{ splitTitle(certainty.title_certainty)[0] }}
          <!-- todo add computedContactPersons reactivity (same as above) after organogram/certainties shareholders (v-model is known) -->
          <SearchInput
            v-model="certainty.values.signers.client[0].id"
            field-name="id"
            :errors="errorMessages[`${key}.values.signers.client.0.id`]"
            :show-error-messages="false"
            :entities="computedContactPersons"
            :disabled="!certainty.selected"
            class="d-inline-block input-search"
          />
          {{ splitTitle(certainty.title_certainty)[1] }}
          <AmountInput
            v-model="certainty.values.suretyship_amount"
            :errors="errorMessages[`${key}.values.suretyship_amount`]"
            :show-error-messages="false"
            :inner-label="record.sales_status.currency"
            :disabled="!certainty.selected"
            class="input-amount"
            @enter="handleSave"
          />
        </template>

        <template v-else-if="certainty.type === 'expired_pledge_prohibition'">
          {{ splitTitle(certainty.title_certainty)[0] }}
          <SearchInput
            v-model="certainty.values.debtor_name"
            :errors="errorMessages[`${key}.values.debtor_name`]"
            :show-error-messages="false"
            :entities="record.config.debtors"
            :initial-placeholder="$t('ui.placeholders.search_debtor')"
            :disabled="!certainty.selected"
            class="d-inline-block input-search"
          />
        </template>

        <template v-else-if="certainty.type === 'irrevocable_payment_guarantee'">
          {{ splitTitle(certainty.title_certainty)[0] }}
          <SearchInput
            v-model="certainty.values.debtor_name"
            :errors="errorMessages[`${key}.values.debtor_name`]"
            :show-error-messages="false"
            :entities="record.config.debtors"
            :initial-placeholder="$t('ui.placeholders.search_debtor')"
            :disabled="!certainty.selected"
            class="d-inline-block input-search"
          />
        </template>

        <template v-else-if="certainty.type === 'bank_guarantee'">
          {{ splitTitle(certainty.title_certainty)[0] }}
          <AmountInput
            v-model="certainty.values.guarantee_amount"
            :errors="errorMessages[`${key}.values.guarantee_amount`]"
            :show-error-messages="false"
            :inner-label="record.sales_status.currency"
            :disabled="!certainty.selected"
            class="input-amount"
            @enter="handleSave"
          />
        </template>

        <template v-else-if="certainty.type === 'release_debtors_house_banker'">
          {{ splitTitle(certainty.title_certainty)[0] }}
          <BaseInput
            v-model="certainty.values.financial_facility_name"
            :errors="errorMessages[`${key}.values.financial_facility_name`]"
            :show-error-messages="false"
            :disabled="!certainty.selected"
            @enter="handleSave"
          />
        </template>

        <template v-else-if="certainty.type === 'mortgage_registration'">
          <BaseSelect
            v-model="certainty.values.count"
            :errors="errorMessages[`${key}.values.count`]"
            :show-error-messages="false"
            :disabled="!certainty.selected"
            :options="readableCountsToFive"
            :full-width="false"
          />
          {{ splitTitle(certainty.title_certainty)[1] }}
          <BaseInput
            v-model="certainty.values.address.address"
            :errors="errorMessages[`${key}.values.address.address`]"
            :show-error-messages="false"
            :disabled="!certainty.selected"
            class="input-address"
            @enter="handleSave"
          />
          {{ splitTitle(certainty.title_certainty)[2] }}
          (<BaseInput
            v-model="certainty.values.address.postal_code"
            :errors="errorMessages[`${key}.values.address.postal_code`]"
            :show-error-messages="false"
            :disabled="!certainty.selected"
            class="input-address-postal-code"
            @enter="handleSave"
          />)
          <BaseInput
            v-model="certainty.values.address.city"
            :errors="errorMessages[`${key}.values.address.city`]"
            :show-error-messages="false"
            :disabled="!certainty.selected"
            class="input-address me-2"
            @enter="handleSave"
          />
          <SearchInput
            v-model="certainty.values.address.country_id"
            field-name="id"
            :errors="errorMessages[`${key}.values.address.country_id`]"
            :show-error-messages="false"
            :disabled="!certainty.selected"
            :entities="getCountries"
            class="input-address"
          />
          {{ splitTitle(certainty.title_certainty)[4] }}
          <AmountInput
            v-model="certainty.values.mortgage_amount"
            :errors="errorMessages[`${key}.values.mortgage_amount`]"
            :show-error-messages="false"
            :inner-label="record.sales_status.currency"
            :disabled="!certainty.selected"
            class="input-amount"
            @enter="handleSave"
          />
        </template>

        <template v-else-if="certaintyTypesWithCountableDropdown.includes(certainty.type)">
          <BaseSelect
            v-model="certainty.values.count"
            :errors="errorMessages[`${key}.values.count`]"
            :show-error-messages="false"
            :disabled="!certainty.selected"
            :options="readableCounts"
            :full-width="false"
          />
          {{ splitTitle(certainty.title_certainty)[1] }}
        </template>

        <template v-else-if="certainty.type === 'custom'">
          <BaseInput
            v-model="certainty.values.custom_certainty_title"
            :errors="errorMessages[`${key}.values.custom_certainty_title`]"
            :show-error-messages="false"
            :disabled="!certainty.selected"
            class="w-100"
            @enter="handleSave"
          />
        </template>
        <template v-else>
          {{ certainty.title_certainty_parsed }}
        </template>
      </td>
      <td class="action-buttons">
        <FormIconButton
          v-if="certaintyIsRemovable(certainty, key)"
          :disabled="!certainty.selected"
          :button-style="certainty.selected ? 'primary' : 'secondary'"
          button-size="sm"
          icon="fa-solid fa-minus"
          outline
          @click.prevent="deleteDuplicateCertainty(key)"
        />
        <FormIconButton
          v-if="certaintyIsDuplicable(certainty, key)"
          :disabled="!certainty.selected"
          :button-style="certainty.selected ? 'primary' : 'secondary'"
          button-size="sm"
          outline
          icon="fa-solid fa-plus"
          @click.prevent="duplicateCertainty(key, certainty.type)"
        />
      </td>
    </tr>
  </ResponsiveTable>
  <FormFooter
    class="mt-auto"
    :pending="loading"
    :disabled="_.isEqual(record, originalRecord) || loading"
    @save="handleSave"
    @cancel="handleCancel"
  />
</template>

<script setup>
import ResponsiveTable from '@/components/tables/ResponsiveTable.vue';
import BaseCheckbox from '@/components/form/BaseCheckbox.vue';
import FormIconButton from '@/components/buttons/FormIconButton.vue';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import apiClient from '@/services/ApiClient';
import {
  useAmountSuffixConvertCentsToAmount,
  useAmountSuffixConvertAmountToCents,
} from '@/composables/UseNumberManipulation';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import BaseInput from '@/components/form/BaseInput.vue';
import EnumSelect from '@/components/form-controls/EnumSelect.vue';
import SearchInput from '@/components/form-controls/SearchInput/SearchInput.vue';
import AmountInput from '@/components/form-controls/AmountInput.vue';
import BaseSelect from '@/components/form/BaseSelect.vue';
import { useEnumerationsStore } from '@/stores/enumerations';
import { useBeforeRouteLeave, useSetOriginalRecord, useSetRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import { APPROVED, EVALUATION } from '@/configs/constants/Status';
import BaseModal from '@/components/BaseModal.vue';
import {
  useGetProspectCreditProposalOwner,
  useGetProspectCreditProposalStatus,
  useMapContactPersons,
} from '@/composables/UseProspectViewData';
import { ref, computed, onBeforeMount } from 'vue';
import { nanoid } from 'nanoid';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { required } from '@vuelidate/validators';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
    required: true,
  },
  contactPersons: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['save']);

const router = useRouter();
const { t } = useI18n();
const enums = useEnumerationsStore();

const record = ref(props.initialRecord);
const originalRecord = ref({});
const validationRules = ref({});
const errorMessages = ref([]);
const loading = ref(false);
const formId = nanoid();
const showConfirmCreditProposalDataModal = ref(false);
const creditProposalUpdateDataNotAllowed =
  useGetProspectCreditProposalStatus() === EVALUATION && !useGetProspectCreditProposalOwner();
let readableCounts = ref({});
let readableCountsToFive = ref({});

const certaintyValuesRecord = {
  personal_suretyship: {
    signers: {
      client: [
        {
          persons: [
            {
              id: null,
              address_id: null,
              salutation: null,
              initials: null,
              first_name: null,
              last_name: null,
              uuid: null,
              deleted_at: null,
              name: null,
            },
          ],
        },
      ],
    },
    suretyship_amount: '',
    suretyship_amount_readable: null,
    currency: record.value.sales_status.currency,
    co_sign: true,
    co_signer: {
      id: null,
    },
    agreement: {
      city: 'Houten',
      date: '2022-11-15',
    },
  },

  business_suretyship: {
    signers: {
      client: [
        {
          id: null,
          legal_form_id: null,
          address_id: null,
          director_id: null,
          name: null,
          phone: null,
          website: null,
          registered_office: null,
          company_registration_number: null,
          vat_number: null,
          deleted_at: null,
          name_plus_legal_form: null,
          legal_form: {
            id: null,
            name: null,
          },
        },
      ],
    },
    suretyship_amount: '',
    suretyship_amount_readable: null,
    currency: record.value.sales_status.currency,
    co_sign: true,
    co_signer: {
      id: null,
    },
    agreement: {
      city: 'Amersfoort',
      date: '2022-11-15',
    },
  },
  release_debtors_house_banker: {
    financial_facility_name: null,
    agreement: { city: 'Houten', date: '2022-12-09' },
  },
  expired_pledge_prohibition: {
    debtor_name: null,
  },
  irrevocable_payment_guarantee: {
    debtor_name: null,
  },
  mortgage_registration: {
    count: null,
    count_readable: null,
    mortgage_amount: '',
    currency: record.value.sales_status.currency,
    address: {
      id: null,
      country_id: null,
      address: null,
      postal_code: null,
      city: null,
    },
    agreement: {
      city: null,
      date: null,
    },
  },
  custom: {
    custom_certainty_title: null,
    agreement: {
      city: null,
      date: null,
    },
  },
};

const certaintyTypesWithCountableDropdown = [
  'pledge_stock',
  'pledge_stock_inventory',
  'pledge_inventory',
  'pledge_receivables',
  'pledge_orders',
  'pledge_rights_credit_insurance_no_vars',
  'pledge_transport_insurance',
  'pledge_profits',
  'pledge_trademark_rights_domain_names',
];

const getCountries = computed(() => {
  const countries = {};
  if (enums.data.countries) {
    enums.data.countries.map((country) => {
      countries[country.id] = country.translation;
    });
  }
  return countries;
});

const computedContactPersons = computed(() => {
  unsetCertaintiesPersonIdIfSelectedContactPersonDoesNotExists();
  return useMapContactPersons(props.contactPersons);
});

onBeforeMount(async () => {
  useBeforeRouteLeave();
  const language = record.value.sales_status.correspondence_language.replace('-', '_');

  readableCounts.value = {
    1: t(`ui.numbers.readable_counts.${1}`, 1, { locale: language }),
    2: t(`ui.numbers.readable_counts.${2}`, 1, { locale: language }),
    3: t(`ui.numbers.readable_counts.${3}`, 1, { locale: language }),
  };

  readableCountsToFive.value = {
    1: t(`ui.numbers.readable_counts.${1}`, 1, { locale: language }),
    2: t(`ui.numbers.readable_counts.${2}`, 1, { locale: language }),
    3: t(`ui.numbers.readable_counts.${3}`, 1, { locale: language }),
    4: t(`ui.numbers.readable_counts.${4}`, 1, { locale: language }),
    5: t(`ui.numbers.readable_counts.${5}`, 1, { locale: language }),
  };

  record.value = useSetRecord(record.value, formId);
  originalRecord.value = useSetOriginalRecord(record.value, formId);
});

async function handleSave() {
  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  await setErrorMessages();
  if (Object.keys(errorMessages.value).length) {
    return;
  }

  if (creditProposalUpdateDataNotAllowed) {
    showConfirmCreditProposalDataModal.value = true;
    return;
  }
  await save();
}
async function save() {
  try {
    loading.value = true;

    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/offer/certaintyacts/available`,
      null,
      { certainty_acts: useAmountSuffixConvertAmountToCents(structuredClone(record.value.data)) }
    );
    useSetToast('success', t('toast.success.offer_certainties_successfully_updated'));
    emit('save', { customerLogs: response.customer_log, offerPreview: response.offer_preview });

    originalRecord.value = useSetOriginalRecord(record.value, formId);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    record.value.data = useAmountSuffixConvertCentsToAmount(record.value.data);
    useSetToast('error', t('toast.error.updating_offer_certainties') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while updating offer certainty: ', error);
  } finally {
    loading.value = false;
  }
}
function handleCancel() {
  record.value = useSetRecord(originalRecord.value, formId);
  errorMessages.value = [];
}

function splitTitle(title) {
  const titleParts = title.split(' ');
  let parsedTitleParts = [];
  let key = 0;

  titleParts.forEach((part) => {
    if (part.includes(':')) {
      key++;
      return;
    }

    parsedTitleParts[key] = key in parsedTitleParts ? `${parsedTitleParts[key]} ${part}` : `${part}`;
  });

  return parsedTitleParts;
}

function certaintyIsRemovable(certainty, key) {
  if (!certainty.duplicable) {
    return false;
  }

  const firstDuplicableCertaintyIndex = record.value.data.findIndex((certaintyAct) => {
    return certaintyAct.title_certainty === certainty.title_certainty;
  });

  if (firstDuplicableCertaintyIndex !== key) {
    return true;
  }
  return false;
}

function certaintyIsDuplicable(certainty, key) {
  if (certainty.duplicable) {
    if (record.value.data[key - 1]?.title_certainty === certainty.title_certainty) {
      return false;
    }
    return true;
  }
  return false;
}

function duplicateCertainty(key, type = null) {
  const duplicate = structuredClone(record.value.data[key]);
  delete duplicate.certainty_act_id;

  if (type) {
    duplicate.values = certaintyValuesRecord[type];
  }

  const lastDuplicableCertaintyIndex = record.value.data.findLastIndex(
    (certainty) => certainty.title_certainty === duplicate.title_certainty
  );

  record.value.data.splice(lastDuplicableCertaintyIndex + 1, 0, structuredClone(duplicate));
}

async function deleteDuplicateCertainty(key) {
  record.value.data.splice(key, 1);
  await setErrorMessages();
}

function handleClickCheckbox(event, certainty) {
  if (certainty.certainty_rule_id === 24) {
    event.preventDefault();
  }
}

function closeCreditProposalModal() {
  showConfirmCreditProposalDataModal.value = false;
}

async function setErrorMessages() {
  createRulesObject();
  validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value.data);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value.data);
}

function unsetCertaintiesPersonIdIfSelectedContactPersonDoesNotExists() {
  record.value.data.forEach((certainty, key) => {
    const contactPersons = props.contactPersons;
    if (Number.isInteger(parseInt(certainty.values?.signers?.client?.[0]?.persons?.[0]?.id))) {
      const personalSuretyPerson = contactPersons.find((contactPerson) => {
        return parseInt(contactPerson.person.id) === parseInt(certainty.values.signers.client[0].persons[0].id);
      });

      if (!personalSuretyPerson?.id) {
        certainty.values.signers.client[0].persons[0].id = null;
        originalRecord.value.data[key].values.signers.client[0].persons[0].id = null;
      }
    }

    if (!Number.isInteger(parseInt(certainty.values?.signers?.client?.[0]?.id))) {
      return;
    }

    const corporateSuretyPerson = contactPersons.find((contactPerson) => {
      return parseInt(contactPerson.person.id) === parseInt(certainty.values.signers.client[0].id);
    });

    if (!corporateSuretyPerson?.id) {
      certainty.values.signers.client[0].id = null;
      originalRecord.value.data[key].values.signers.client[0].id = null;
    }
  });
}

function createRulesObject() {
  validationRules.value = {};

  record.value.data?.forEach((certaintyItem, key) => {
    if (!certaintyItem.selected) {
      return;
    }

    if (certaintyTypesWithCountableDropdown.includes(certaintyItem.type)) {
      Object.assign(validationRules.value, {
        [key]: {
          values: {
            count: { required },
          },
        },
      });
    }

    if (certaintyItem.type === 'pledge_rights_credit_insurance') {
      Object.assign(validationRules.value, {
        [key]: {
          values: {
            count: { required },
            insurance_policy_number: { required },
            insurer: {
              id: { required },
            },
          },
        },
      });
    }

    if (certaintyItem.type === 'personal_suretyship') {
      Object.assign(validationRules.value, {
        [key]: {
          values: {
            suretyship_amount: { required },
            signers: {
              client: {
                [0]: {
                  persons: {
                    [0]: {
                      id: { required },
                    },
                  },
                },
              },
            },
          },
        },
      });
    }

    if (certaintyItem.type === 'business_suretyship') {
      Object.assign(validationRules.value, {
        [key]: {
          values: {
            suretyship_amount: { required },
            signers: {
              client: {
                [0]: {
                  id: { required },
                },
              },
            },
          },
        },
      });
    }

    if (certaintyItem.type === 'expired_pledge_prohibition' || certaintyItem.type === 'irrevocable_payment_guarantee') {
      Object.assign(validationRules.value, {
        [key]: {
          values: {
            debtor_name: { required },
          },
        },
      });
    }

    if (certaintyItem.type === 'bank_guarantee') {
      Object.assign(validationRules.value, {
        [key]: {
          values: {
            guarantee_amount: { required },
          },
        },
      });
    }

    if (certaintyItem.type === 'release_debtors_house_banker') {
      Object.assign(validationRules.value, {
        [key]: {
          values: {
            financial_facility_name: { required },
          },
        },
      });
    }

    if (certaintyItem.type === 'mortgage_registration') {
      Object.assign(validationRules.value, {
        [key]: {
          values: {
            count: { required },
            address: {
              address: { required },
              postal_code: { required },
              city: { required },
              country_id: { required },
            },
            mortgage_amount: { required },
          },
        },
      });
    }

    if (certaintyItem.type === 'custom') {
      Object.assign(validationRules.value, {
        [key]: {
          values: {
            custom_certainty_title: { required },
          },
        },
      });
    }
  });
}
</script>
