<template>
  <BaseModal
    v-if="showConfirmSaveStatusModal"
    :show="showConfirmSaveStatusModal"
    :modal-title="$t('ui.confirm')"
    size="sm"
    @confirm="handleSaveLogbook"
    @close="closeShowConfirmSaveStatusModal"
  >
    <span
      v-safe-html="
        t('modal.warning_text_when_changing_status_old_to_new', {
          old: `<b>${computedOldStatus}</b>`,
          new: `<b>${computedNewStatus}</b>`,
        })
      "
    />
  </BaseModal>
  <div class="d-flex mt-3 pb-1 mb-1 px-5 mx-4">
    <div class="sales-status-selector status-selector d-inline-flex btn-group-sm mb-4 me-4">
      <div
        v-for="(status, i) in record.statuses"
        :key="i"
        :class="{
          disabled: validateStatus(status.data.type.toLowerCase()),
          outline: status.data.id <= salesStatusData.customer_status_id,
        }"
        class="px-2"
        @click.prevent="changeStatus(status)"
      >
        {{ $t(`sales.status.${status.data.type.toLowerCase()}`) }}
      </div>
      <div
        v-for="(abortStatus, i) in record.abort_statuses"
        :key="i"
        :class="{ outline: abortStatus.name === salesStatusData.abort_status }"
        class="px-2"
        @click.prevent="changeStatus(abortStatus)"
      >
        {{ $t(`sales.status.${abortStatus.name}`) }}
      </div>
    </div>
  </div>
</template>

<script setup>
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import { useAuthorizationStore } from '@/stores/authorization';
import BaseModal from '@/components/BaseModal.vue';
import { TRANSLATED_CUSTOMER_STATUS } from '@/configs/constants/CustomerStatus';
import { APPROVED } from '@/configs/constants/Status';
import { ref, onBeforeMount, computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
  prospectStatus: {
    type: Object,
    default: () => ({}),
  },
});
const emit = defineEmits(['save']);

const { t } = useI18n();
const authorizationStore = useAuthorizationStore();

const record = ref(props.initialRecord);
const showConfirmSaveStatusModal = ref(false);
const selectedStatusId = ref(null);
const selectedAbortStatusName = ref(null);

const salesStatusData = ref({});

const computedOldStatus = computed(() => {
  if (salesStatusData.value?.abort_status) {
    return t(`sales.status.${salesStatusData.value.abort_status.toLowerCase()}`).toLowerCase();
  }

  const currentStatus = getCurrentStatus(salesStatusData.value?.customer_status_id)?.data;
  return TRANSLATED_CUSTOMER_STATUS[currentStatus?.type]?.toLowerCase();
});
const computedNewStatus = computed(() => {
  if (selectedAbortStatusName.value && selectedAbortStatusName.value !== salesStatusData.value?.abort_status) {
    return t(`sales.status.${selectedAbortStatusName.value.toLowerCase()}`).toLowerCase();
  }

  const currentStatus = getCurrentStatus(selectedStatusId.value)?.data;
  return TRANSLATED_CUSTOMER_STATUS[currentStatus?.type]?.toLowerCase();
});

onBeforeMount(async () => {
  salesStatusData.value = getRecordObject();
  selectedStatusId.value = record.value.customer_status_id;
});

function getRecordObject() {
  return {
    current_user_id: authorizationStore.user.id,
    customer_status_id: record.value?.customer_status_id,
    abort_status: record.value?.abort_status,
    status_titles: null,
    customer_status_title_id: null,
    action: null,
    title: null,
    user_id: null,
    execution_date: null,
    description: null,
  };
}

async function handleSaveLogbook() {
  try {
    const recordCopy = structuredClone(salesStatusData.value);

    // abort status is unset
    if (salesStatusData.value?.abort_status === selectedAbortStatusName.value) {
      salesStatusData.value.abort_status = null;
      recordCopy.abort_status = null;
      selectedAbortStatusName.value = null;
    }

    // abort status is set
    if (selectedAbortStatusName.value) {
      recordCopy.abort_status = selectedAbortStatusName.value;
    } else {
      recordCopy.customer_status_id = selectedStatusId.value;
    }

    const response = await apiClient.request(
      'put',
      `/ifapi/sales_statuses/${record.value.id}/logbook`,
      null,
      recordCopy
    );
    useSetToast('success', t('toast.success.status_successfully_updated'));
    emit('save', response);
  } catch (error) {
    // errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    useSetToast('error', t('toast.error.updating_status') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while creating logbook record: ', error);
    return;
  }

  if (selectedAbortStatusName.value) {
    salesStatusData.value.abort_status = selectedAbortStatusName.value;
  } else {
    salesStatusData.value.customer_status_title_id = null;
    salesStatusData.value.customer_status_id = selectedStatusId.value;
  }
  toggleShowConfirmSaveStatusModal();
}

function toggleShowConfirmSaveStatusModal() {
  showConfirmSaveStatusModal.value = !showConfirmSaveStatusModal.value;
}

function closeShowConfirmSaveStatusModal() {
  toggleShowConfirmSaveStatusModal();
  selectedAbortStatusName.value = salesStatusData.value?.abort_status;
  selectedStatusId.value = salesStatusData.value?.customer_status_id;
}

function validateStatus(statusType) {
  if (
    (statusType === 'client' && salesStatusData.value?.customer_status_title_id !== 23) ||
    salesStatusData.value?.abort_status ||
    (props.prospectStatus.credit_proposal?.status !== APPROVED && statusType === 'contract')
  ) {
    return true;
  }
  return false;
}

function changeStatus(status) {
  if (
    (status?.data?.type && validateStatus(status.data.type.toLowerCase())) ||
    salesStatusData.value?.customer_status_id === status?.data?.id
  ) {
    return;
  }
  if (status?.name) {
    selectedAbortStatusName.value = status.name;
  }
  if (status?.data?.id) {
    selectedStatusId.value = status.data.id;
  }

  toggleShowConfirmSaveStatusModal();
}

function getCurrentStatus(statusId) {
  return record.value.statuses.find((status) => status.data.id === statusId);
}
</script>
