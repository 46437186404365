<template>
  <BaseModal
    v-if="showConfirmCreditProposalDataModal && creditProposalUpdateDataNotAllowed"
    :show="showConfirmCreditProposalDataModal"
    :modal-title="$t('ui.not_allowed')"
    size="md"
    custom-footer
    @confirm="closeCreditProposalModal"
    @close="closeCreditProposalModal"
  >
    {{ $t('modal.not_allowed_to_update_credit_proposal_data_while_ongoing_evaluation') }}
  </BaseModal>
  <ResponsiveTable
    borderless
    hoverable
    class="annual-figures pt-3"
  >
    <template #headers>
      <th scope="col">
        {{
          t('sales.annual_figures.in_currency_times_thousand', { currency: props.initialRecord.sales_status.currency })
        }}
      </th>
      <th
        v-for="(annualFigure, key) in record"
        :key="key"
        class="th-year"
      >
        <span class="pe-0">
          {{ annualFigure.year }}
        </span>
        <span
          v-if="record.length !== 1"
          class="cursor-pointer px-2 py-0 icon-trash"
          @click="removeAnnualFigureColumn(key)"
        >
          <i
            class="fa-regular fa-trash-can md"
            aria-hidden="true"
          />
        </span>
      </th>
    </template>
    <tr class="align-middle">
      <th scope="row">{{ t('sales.annual_figures.revenue') }}</th>
      <td
        v-for="(annualFigure, key) in record"
        :key="key"
      >
        <AmountInput
          v-model="annualFigure.revenue_amount"
          :inner-label="null"
          :errors="errorMessages[`${key}.revenue_amount`]"
          @blur="setGrossMarginPercentage(annualFigure.year)"
          @enter="handleRevenueAmountSaveOnEnter(annualFigure.year)"
        />
      </td>
    </tr>
    <tr class="align-middle">
      <th scope="row">{{ t('sales.annual_figures.gross_profit') }}</th>
      <td
        v-for="(annualFigure, key) in record"
        :key="key"
      >
        <AmountInput
          v-model="annualFigure.gross_profit_amount"
          :inner-label="null"
          :errors="errorMessages[`${key}.gross_profit_amount`]"
          @blur="setGrossMarginPercentage(annualFigure.year)"
          @enter="handleRevenueAmountSaveOnEnter(annualFigure.year)"
        />
      </td>
    </tr>
    <tr class="align-middle td-padding">
      <th scope="row">{{ t('sales.annual_figures.gross_margin_percentage') }}</th>
      <td
        v-for="(annualFigure, key) in record"
        :key="key"
      >
        <template v-if="annualFigure.gross_margin_percentage || parseInt(annualFigure.gross_margin_percentage) === 0">
          {{ annualFigure.gross_margin_percentage }}%
        </template>
        <div
          v-if="errorMessages[`${key}.gross_margin_percentage`]"
          class="form-text text-danger error-messages"
        >
          {{ errorMessages[`${key}.gross_margin_percentage`].join('\r\n') }}
        </div>
      </td>
    </tr>
    <tr class="align-middle">
      <th scope="row">{{ t('sales.annual_figures.opex') }}</th>
      <td
        v-for="(annualFigure, key) in record"
        :key="key"
      >
        <AmountInput
          v-model="annualFigure.opex_amount"
          :inner-label="null"
          :errors="errorMessages[`${key}.opex_amount`]"
          @enter="handleSave"
        />
      </td>
    </tr>
    <tr class="align-middle">
      <th scope="row">{{ t('sales.annual_figures.ebitda') }}</th>
      <td
        v-for="(annualFigure, key) in record"
        :key="key"
      >
        <AmountInput
          v-model="annualFigure.ebitda_amount"
          :inner-label="null"
          :errors="errorMessages[`${key}.ebitda_amount`]"
          @enter="handleSave"
        />
      </td>
    </tr>
    <tr class="align-middle">
      <th scope="row">{{ t('sales.annual_figures.net_profit') }}</th>
      <td
        v-for="(annualFigure, key) in record"
        :key="key"
      >
        <AmountInput
          v-model="annualFigure.net_profit_amount"
          :inner-label="null"
          :errors="errorMessages[`${key}.net_profit_amount`]"
          @enter="handleSave"
        />
      </td>
    </tr>
  </ResponsiveTable>
  <FormFooter
    class="mt-auto"
    :pending="loading"
    :disabled="_.isEqual(record, originalRecord) || loading"
    @save="handleSave"
    @cancel="handleCancel"
  />
</template>

<script setup>
import ResponsiveTable from '@/components/tables/ResponsiveTable.vue';
import AmountInput from '@/components/form-controls/AmountInput.vue';
import FormFooter from '@/components/form-controls/FormFooter.vue';
import apiClient from '@/services/ApiClient';
import { useMergeDefaultVuelidateValidationRules, useVuelidateValidation } from '@/composables/UseVuelidateValidation';
import {
  useAmountSuffixConvertAmountToCents,
  useAmountSuffixConvertCentsToAmount,
  useAmountToCents,
} from '@/composables/UseNumberManipulation';
import { useBeforeRouteLeave, useSetOriginalRecord, useSetRecord } from '@/composables/UseIsDirty';
import { useSetToast } from '@/composables/UseToast';
import BaseModal from '@/components/BaseModal.vue';
import { APPROVED, EVALUATION } from '@/configs/constants/Status';
import {
  useCompareSalesStatusObject,
  useSetAndMergeSalesStatusObject,
} from '@/composables/records/UseAssessmentCommittee';
import {
  useGetProspectCreditProposalOwner,
  useGetProspectCreditProposalStatus,
} from '@/composables/UseProspectViewData';
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { nanoid } from 'nanoid';
import { useRouter } from 'vue-router';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
    required: true,
  },
});
const emit = defineEmits(['save']);

const router = useRouter();
const { t } = useI18n();

const record = ref(props.initialRecord.data);
const originalRecord = ref({});
const validationRules = ref({});
const errorMessages = ref([]);
const loading = ref(false);
const formId = nanoid();
const showConfirmCreditProposalDataModal = ref(false);
const creditProposalUpdateDataNotAllowed =
  useGetProspectCreditProposalStatus() === EVALUATION && !useGetProspectCreditProposalOwner();
let originalCreditProposalObject = {};

onBeforeMount(async () => {
  useBeforeRouteLeave();
  record.value = useSetRecord(record.value, formId);
  originalRecord.value = useSetOriginalRecord(record.value, formId);

  if (creditProposalUpdateDataNotAllowed) {
    originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
  }

  validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);
});

async function handleSave() {
  if (_.isEqual(record.value, originalRecord.value) || loading.value) {
    return;
  }

  if (creditProposalUpdateDataNotAllowed) {
    const latestCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));

    const creditProposalHasDataDifference = useCompareSalesStatusObject(
      originalCreditProposalObject,
      latestCreditProposalObject
    );

    if (creditProposalHasDataDifference) {
      showConfirmCreditProposalDataModal.value = true;
      return;
    }
  }

  validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value);
  errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);

  if (Object.keys(errorMessages.value).length) {
    return;
  }

  await save();
}

async function save() {
  try {
    loading.value = true;
    let recordCopy = structuredClone(record.value);
    let response;

    if (!recordCopy[0].id) {
      response = await apiClient.request(
        'post',
        `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/annual_figures`,
        [],
        {
          annualFigures: useAmountSuffixConvertAmountToCents(recordCopy),
        }
      );
      useSetToast('success', t('toast.success.annual_figures_successfully_created'));
    } else {
      response = await apiClient.request(
        'put',
        `/ifapi/sales_statuses/${router.currentRoute.value.params.sales_status_id}/annual_figures/update`,
        [],
        {
          annualFigures: useAmountSuffixConvertAmountToCents(recordCopy),
        }
      );
      useSetToast('success', t('toast.success.annual_figures_successfully_updated'));
    }

    emit('save', { customerLogs: response.sales_status.customer_log });
    record.value = useSetRecord(useAmountSuffixConvertCentsToAmount(response.data), formId);
    originalRecord.value = useSetOriginalRecord(record.value, formId);
  } catch (error) {
    errorMessages.value = { ...errorMessages.value, ...error?.response?.data?.errors };
    record.value = useAmountSuffixConvertCentsToAmount(record.value);
    if (!record.value[0].id) {
      useSetToast('error', t('toast.error.creating_annual_figures') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while creating annual figures: ', error);
    } else {
      useSetToast('error', t('toast.error.updating_annual_figures') + ':' + '<br>' + error?.response?.data?.message);
      console.error('Error while updating annual figures: ', error);
    }
  } finally {
    loading.value = false;
  }
}

function handleCancel() {
  record.value = useSetRecord(originalRecord.value, formId);
  if (creditProposalUpdateDataNotAllowed) {
    originalCreditProposalObject = structuredClone(useSetAndMergeSalesStatusObject(getCreditProposalObject()));
  }
  errorMessages.value = [];
}

function handleRevenueAmountSaveOnEnter(year) {
  setGrossMarginPercentage(year);
  handleSave();
}

function setGrossMarginPercentage(year) {
  const entityIndex = record.value.findIndex((entity) => entity.year === year);
  const annualFigure = record.value[entityIndex];
  if (annualFigure.gross_profit_amount && annualFigure.revenue_amount) {
    record.value[entityIndex].gross_margin_percentage = calculateGrossMarginPercentage(annualFigure);
  } else {
    record.value[entityIndex].gross_margin_percentage = null;
  }
}

function calculateGrossMarginPercentage(annualFigure) {
  return Math.round(
    useAmountToCents(annualFigure.gross_profit_amount) / useAmountToCents(annualFigure.revenue_amount)
  ).toString();
}

async function removeAnnualFigureColumn(key) {
  if (record.value.length === 1) {
    return;
  }
  record.value.splice(key, 1);

  if (Object.entries(errorMessages.value).length) {
    validationRules.value = useMergeDefaultVuelidateValidationRules(validationRules.value, record.value);
    errorMessages.value = await useVuelidateValidation(validationRules.value, record.value);
  }
}

function getCreditProposalObject() {
  const annualFigures = { annual_figures: structuredClone(record.value) };
  return { sales_status: annualFigures };
}

function closeCreditProposalModal() {
  showConfirmCreditProposalDataModal.value = false;
}
</script>
