import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAuthorizationStore = defineStore('authorization', () => {
  const user = ref({});
  const noRouteAccess = ref(false);
  const email = ref();

  async function setCurrentUser(currentUser) {
    user.value = currentUser;
  }

  return { user, noRouteAccess, email, setCurrentUser };
});
