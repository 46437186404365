<template>
  <BaseRow class="h-100">
    <PageNav v-if="route.params?.sales_status_id" />
    <BaseColumn class="page-content">
      <ViewSpacing
        class="pb-0"
        classes="h-100"
      >
        <div class="d-flex flex-column h-100">
          <RouterView />
        </div>
      </ViewSpacing>
    </BaseColumn>
  </BaseRow>
</template>

<script setup>
import PageNav from '@/components/navigation/PageNav.vue';
import ViewSpacing from '@/components/ViewSpacing.vue';
import { BaseRow, BaseColumn } from '@impact-factoring/impact-branding';
import { RouterView, useRoute } from 'vue-router';

const route = useRoute();
</script>
