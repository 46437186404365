<template>
  <ConfirmModal
    v-if="showLeavePageConfirmModal"
    :show="showLeavePageConfirmModal"
    :message="t('modal.warning_text_unsaved_changes_when_leaving_contact_person_form')"
    @confirm="handleConfirmLeaveForm"
    @close="closeLeavePageConfirmModal"
  />
  <ConfirmModal
    v-if="showDeleteConfirmModal"
    :show="showDeleteConfirmModal"
    @confirm="handleConfirmDeleteContactPerson(oldContactPersonId)"
    @close="closeDeleteConfirmModal"
  >
    <span
      v-safe-html="
        t('modal.warning_text_when_deleting_contact_person', { name: getContactPersonString(oldContactPersonId) })
      "
    />
    <template v-if="contactPersonConfirmString">
      <br />
      <span>{{ t('modal.warning_text_when_deleting_contact_person_of_prospect_organogram_company') }}</span>
      <span v-safe-html="contactPersonConfirmString" />
    </template>
  </ConfirmModal>
  <template v-if="record.contact_persons[0]?.id || record.contact_persons[1]?.id">
    <div class="d-flex justify-content-between filters-container contact-persons-filters mt-4 pt-2">
      <div class="d-flex filters-wrapper">
        <div
          :class="{ active: filter === filters.allPersons }"
          @click="setFilter(filters.allPersons)"
        >
          {{ $t('contact_persons.all_persons') }}
        </div>
        <div
          :class="{ active: filter === filters.contactPersons }"
          @click="setFilter(filters.contactPersons)"
        >
          {{ $t('contact_persons.contact_persons') }}
        </div>
      </div>
      <ActionAddButton
        :label="$t('modal.person')"
        @click="handleOpenContactPersonCreateForm"
      />
    </div>
  </template>
  <template v-else>
    <div class="d-flex justify-content-end align-items-center mt-4 pt-3 mb-4 pb-3">
      <ActionAddButton
        :label="$t('navigation.contactperson')"
        @click="handleOpenContactPersonCreateForm"
      />
    </div>
  </template>
  <div class="table-sticky-head table-contact-persons">
    <ContactPersonForm
      v-if="showCreateContactPersonForm && record.contact_persons.length"
      :initial-record="record.contact_persons[record.contact_persons.length - 1]"
      :initial-validation-rules="validationRules[0]"
      :company-id="props.companyId"
      label-columns="col-xl-3 col-12"
      input-columns="col-xl-5 col-12"
      @save="handleEmitSave"
      @before-mount="handleEmitBeforeMount"
    />
    <DataTable
      v-if="(record.contact_persons.length && record.contact_persons[0]?.id) || record.contact_persons.length > 1"
      :headers="headers"
      :hoverable="false"
    >
      <template
        v-for="(contactPerson, key) in computedContactPersons"
        :key="contactPerson"
      >
        <tr
          v-if="contactPerson.id"
          class="cursor-pointer"
          @click="handleToggleContactPersonUpdateForm(contactPerson.id)"
        >
          <td class="text-truncate">
            {{ contactPerson.person.initials }} {{ contactPerson.person.last_name }}
            {{ contactPerson.person.first_name }}
          </td>
          <td class="text-truncate">
            {{ contactPerson.contact_detail.phone_primair || '-' }}
          </td>
          <td class="text-truncate">
            {{ contactPerson.contact_detail.email_address || '-' }}
          </td>
          <td class="action-icons">
            <BaseIcon icon="fa-solid fa-angle-down" />
          </td>
        </tr>
        <tr
          v-if="showUpdateContactPersonForm && oldContactPersonId === contactPerson.id"
          class="contact-person-table-form"
        >
          <td colspan="4">
            <ContactPersonForm
              :initial-record="contactPerson"
              :initial-validation-rules="validationRules[key]"
              :company-id="props.companyId"
              label-columns="col-xl-3 col-12"
              input-columns="col-xl-5 col-12"
              @update="handleEmitUpdate"
              @delete="openDeleteConfirmModal(contactPerson.id)"
              @before-mount="handleEmitBeforeMount"
            />
          </td>
        </tr>
      </template>
    </DataTable>
  </div>
</template>

<script setup>
import ActionAddButton from '@/components/buttons/ActionAddButton.vue';
import DataTable from '@/components/tables/DataTable.vue';
import apiClient from '@/services/ApiClient';
import { useSetToast } from '@/composables/UseToast';
import ConfirmModal from '@/components/ConfirmModal.vue';
import { useGetOriginalProspectRecord, useGetProspectRecord, useRemoveProspectFormId } from '@/composables/UseIsDirty';
import ContactPersonForm from '@/forms/sales/sales-status/ContactPersonForm.vue';
import { BaseIcon } from '@impact-factoring/impact-branding';
import { useI18n } from 'vue-i18n';
import { computed, onBeforeMount, ref } from 'vue';
import { email, required } from '@vuelidate/validators';
import _ from 'lodash';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
  companyId: {
    type: Number,
    default: null,
  },
});
const emit = defineEmits(['save', 'beforeMount']);

const { t } = useI18n();
const record = ref(props.initialRecord);
const headers = [t('general.name'), t('general.phone'), t('general.email'), ''];
const showCreateContactPersonForm = ref(false);
const showUpdateContactPersonForm = ref(false);
const showLeavePageConfirmModal = ref(false);
const showDeleteConfirmModal = ref(false);
const oldContactPersonId = ref(null);
const preConfirmContactPersonId = ref(null);
const validationRules = ref(createValidationRules());
const contactPersonFormId = ref(null);
const filters = ref({
  allPersons: 'all_persons',
  contactPersons: 'contact_persons',
});
const requestedFilter = ref(filters.value.allPersons);
const filter = ref(filters.value.allPersons);
const contactPersonConfirmString = ref(null);
let originalRecord = [];

const computedContactPersons = computed(() => {
  let contactPersons = structuredClone(record.value.contact_persons);

  if (filter.value === filters.value.contactPersons) {
    contactPersons = getFilteredContactPersons(contactPersons);
  }

  return _.orderBy(contactPersons, 'id', 'desc');
});

function getFilteredContactPersons(contactPersons) {
  return contactPersons.filter(
    (contactPerson) => contactPerson.contact_detail.email_address || contactPerson.contact_detail.phone_primair
  );
}

onBeforeMount(() => {
  addContactPersonToRecord();
});

function createContactPerson() {
  return {
    job_title: null,
    person: {
      salutation: null,
      initials: null,
      first_name: null,
      last_name: null,
      competence: null,
    },
    contact_detail: {
      phone_primair: null,
      email_address: null,
    },
    contact_types: [],
  };
}
function createValidationRules() {
  let validationRules = [];

  const validationRule = {
    person: {
      last_name: { required },
    },
    contact_detail: {
      email_address: { email },
    },
  };
  record.value.contact_persons.forEach(() => {
    validationRules = [...validationRules, validationRule];
  });

  return validationRules;
}

async function deleteContactPerson(contactPersonId) {
  return await apiClient.request('delete', `/ifapi/companies/${props.companyId}/contactpersons/${contactPersonId}`);
}

async function confirmContactPerson(personId) {
  return await apiClient.request('get', `/ifapi/companies/${props.companyId}/contactpersons/${personId}/confirm`);
}

async function handleConfirmDeleteContactPerson(contactPersonId) {
  closeDeleteConfirmModal();
  try {
    const response = await deleteContactPerson(contactPersonId);
    useSetToast('success', t('toast.success.contact_person_successfully_deleted'));
    response.contact_persons = mapContactPersons(response.contact_persons);
    emit('save', {
      customerLogs: response.customer_log,
      contactPersons: response.contact_persons,
      offerPreview: response.offer_preview,
      creditProposalPreview: response.credit_proposal_preview,
    });

    record.value.contact_persons = response.contact_persons;
    addContactPersonToRecord();
    validationRules.value = createValidationRules();
    useRemoveProspectFormId(contactPersonFormId.value);
  } catch (error) {
    useSetToast('error', t('toast.error.deleting_contact_person') + ':' + '<br>' + error?.response?.data?.message);
    console.error('Error while deleting contactperson: ', error);
  }
}

function handleOpenContactPersonCreateForm() {
  if (
    !_.isEqual(useGetProspectRecord(contactPersonFormId.value), useGetOriginalProspectRecord(contactPersonFormId.value))
  ) {
    preConfirmContactPersonId.value = null;
    showLeavePageConfirmModal.value = true;
    return;
  }
  addContactPersonToRecord();
  openCreateContactPersonForm();
}

function handleToggleContactPersonUpdateForm(id) {
  if (
    !_.isEqual(useGetProspectRecord(contactPersonFormId.value), useGetOriginalProspectRecord(contactPersonFormId.value))
  ) {
    preConfirmContactPersonId.value = id;
    showLeavePageConfirmModal.value = true;
    return;
  }

  toggleContactPersonForm(id);
}

function handleConfirmLeaveForm() {
  if (requestedFilter.value === filter.value) {
    toggleContactPersonForm(preConfirmContactPersonId.value);
  }

  preConfirmContactPersonId.value = null;
  showLeavePageConfirmModal.value = false;
  useRemoveProspectFormId(contactPersonFormId.value);
  record.value.contact_persons = structuredClone(originalRecord);

  if (requestedFilter.value !== filter.value) {
    filter.value = structuredClone(requestedFilter.value);
  }
}

function toggleContactPersonForm(id) {
  if (!id) {
    openCreateContactPersonForm();
    return;
  }

  if (oldContactPersonId.value === id) {
    showUpdateContactPersonForm.value = false;
    oldContactPersonId.value = null;
    return;
  }

  showUpdateContactPersonForm.value = true;
  showCreateContactPersonForm.value = false;
  oldContactPersonId.value = id;
}

function openCreateContactPersonForm() {
  oldContactPersonId.value = null;
  showUpdateContactPersonForm.value = false;
  showCreateContactPersonForm.value = true;
}

function addContactPersonToRecord() {
  if (
    record.value.contact_persons[record.value.contact_persons.length - 1]?.id ||
    !record.value.contact_persons.length
  ) {
    record.value.contact_persons = [...record.value.contact_persons, createContactPerson()];
    validationRules.value = createValidationRules();
  }
}

async function openDeleteConfirmModal(contactPersonId) {
  const contactPerson = record.value.contact_persons.find((contactPerson) => contactPerson.id === contactPersonId);

  if (contactPerson?.person?.id) {
    const response = await confirmContactPerson(contactPerson.person.id);
    contactPersonConfirmString.value = getProspectOrganogramString(response);
  }

  showDeleteConfirmModal.value = true;
}
function closeDeleteConfirmModal() {
  showDeleteConfirmModal.value = false;
}
function closeLeavePageConfirmModal() {
  requestedFilter.value = structuredClone(filter.value);
  showLeavePageConfirmModal.value = false;
}

function handleEmitSave(data) {
  emit('save', data);
  record.value.contact_persons = data.contactPersons;
  originalRecord = structuredClone(record.value.contact_persons);
  toggleContactPersonForm(record.value.contact_persons[record.value.contact_persons.length - 1].id);
  addContactPersonToRecord();
}

function handleEmitUpdate(data) {
  emit('save', data);
  originalRecord = structuredClone(record.value.contact_persons);
  addContactPersonToRecord();
}

function handleEmitBeforeMount(formId) {
  emit('beforeMount', formId);
  originalRecord = structuredClone(record.value.contact_persons);
  contactPersonFormId.value = formId;
}

function mapContactPersons(contactPersons) {
  return contactPersons.map((contactPerson) => {
    contactPerson.person.competence = contactPerson.person.competence?.competence;
    return contactPerson;
  });
}

function getContactPerson(id) {
  return record.value.contact_persons.find((contactPerson) => contactPerson.id === id);
}

function getContactPersonString(id) {
  const contactPerson = getContactPerson(id);
  if (contactPerson?.person.initials) {
    return '<b>' + contactPerson.person.initials + ' ' + contactPerson.person.last_name + '</b>';
  }

  if (contactPerson?.person.first_name) {
    return '<b>' + contactPerson.person.first_name + ' ' + contactPerson.person.last_name + '</b>';
  }
  return '<b>' + contactPerson.person.last_name + '</b>';
}

function setFilter(value) {
  requestedFilter.value = value;

  if (
    !showCreateContactPersonForm.value &&
    !_.isEqual(useGetProspectRecord(contactPersonFormId.value), useGetOriginalProspectRecord(contactPersonFormId.value))
  ) {
    showLeavePageConfirmModal.value = true;
    return;
  }

  filter.value = value;
}

function getProspectOrganogramString(organogramCards) {
  let string = '';

  _.uniqBy(organogramCards, 'customer_organogram.sales_status.id').forEach((card, key) => {
    string += '<b>' + card.customer_organogram.sales_status.company.name_plus_legal_form + '</b>';

    if (key !== _.uniqBy(organogramCards, 'customer_organogram.sales_status.id')?.length - 1) {
      string += ', ';
    }
    string += '<br />';
  });

  return string;
}
</script>
