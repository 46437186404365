<template>
  <div
    ref="chartContainer"
    :class="{ 'chart-container-fullscreen': isFullScreen }"
  >
    <div
      v-if="!isFullScreen"
      class="icon icon-wrapper cursor-pointer mb-3"
      @click="fullScreen"
    >
      <i class="fa-solid fa-expand icon" />
      {{ $t('sales.organogram.full_screen') }}
    </div>
  </div>
</template>

<script setup>
import { useMapContactPersons } from '@/composables/UseProspectViewData';
import { useEnumerationsStore } from '@/stores/enumerations';
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';
import { OrgChart } from 'd3-org-chart';

const props = defineProps({
  initialRecord: {
    type: Object,
    default: () => ({}),
  },
  contactPersons: {
    type: Object,
    default: () => ({}),
  },
});

const enums = useEnumerationsStore();
const record = ref(props.initialRecord);
const chartContainer = ref(null);
const isFullScreen = ref(false);
let chartReference = null;

const computedContactPersons = computed(() => {
  return useMapContactPersons(props.contactPersons);
});

const computedCompanies = computed(() => {
  return Object.values(enums.data.companies).map((company) => {
    return {
      id: company.id,
      name: company.name,
    };
  });
});

onBeforeMount(async () => {
  if (!record.value.meta) {
    record.value.meta = getDefaultMetaData();
  }

  record.value.cards = getMappedCards();
});

onMounted(() => {
  chartReference = new OrgChart();
  renderChart();
  expandAll();
  chartReference.fit();
  document.addEventListener('fullscreenchange', handleFullScreenChange);
});

onBeforeUnmount(() => {
  chartReference = null;
});

function getDefaultMetaData() {
  return {
    compact: false,
    layout: 'top',
  };
}

function expandAll() {
  chartReference.expandAll();
}

function fullScreen() {
  chartReference.fullscreen();
  chartReference.fit();
}

function renderChart() {
  chartReference
    .container(chartContainer.value)
    .compact(record.value.meta.compact)
    .layout(record.value.meta.layout)
    .data(record.value.cards)
    .nodeId((d) => d.card_id)
    .parentNodeId((d) => d.parent_card_id)
    .nodeHeight((d) => 85)
    .nodeWidth((d) => 220 + 2)
    .childrenMargin((d) => 50)
    .compactMarginBetween((d) => 35)
    .compactMarginPair((d) => 30)
    .neighbourMargin((a, b) => 20)
    .siblingsMargin((d) => 25)
    .nodeContent(function (d, i, arr, state) {
      return `
            <div
              style="font-family: 'Roboto', sans-serif; background-color: #fff; position: absolute; word-break: break-all; box-shadow: 0 0 0 3px rgba(0, 173, 239, 0.125); border-radius: 12px; border: 1px solid #00adef; width:${
                d.width
              }px; height:${d.height}px"
            >
              <div style="height: 100%; display: flex; flex-direction: column; justify-content: center;">
                <div style="font-size: 14px; color: #333; padding: 0 2rem;">${d.data.name || ''}</div>
              </div>
           </div>
  `;
    })
    .render();
}

function getMappedCards() {
  return record.value.cards.map((card) => {
    let name;

    if (card.company_id) {
      const company = computedCompanies.value.find((company) => company.id === card.company_id);
      name = company?.name;
    }

    if (card.person_id) {
      const person = computedContactPersons.value.find((contactPerson) => {
        return contactPerson.id === card.person_id;
      });
      name = person?.name;
    }

    if (card.company_id && card.person_id) {
      name = null;
    }

    return {
      id: card.id,
      card_id: card.card_id,
      parent_card_id: card.parent_card_id,
      name: name,
      person_id: card.person_id,
      company_id: card.company_id,
    };
  });
}

function handleFullScreenChange() {
  isFullScreen.value = !!document.fullscreenElement;
  chartReference?.fit();
}
</script>
